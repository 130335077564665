// External Libraries
import React from "react";

class ClassificationDropdown extends React.Component {
	render() {
		const {
			index,
			classification,
			activeClassificationDropdown,
			sortedClassifications,
			handleClassificationClick,
			handleClassificationChange
		} = this.props;

		return (
			<div className="classification-wrapper">
				<div onClick={event => handleClassificationClick(index, event)}>{classification || "Unclassified"}</div>
				{activeClassificationDropdown === index && (
					<div className="classification-dropdown">
						{sortedClassifications.map(classification => (
							<div key={classification.name} className={`dropdown-item`} onClick={() => handleClassificationChange(index, classification.name)}>
								{classification.name}
							</div>
						))}
					</div>
				)}
			</div>
		);
	}
}

export default ClassificationDropdown;
