import clsx from "clsx";
import Modal from "react-responsive-modal";

export const DeleteModal = ({ show, onClose }) => {
	return (
		<Modal open={show} onClose={onClose} center>
			<div className="modal-title">Delete Engagement?</div>
			<div className={clsx("modal-content", "ft-eng-det-del-modal-message")}>Are you sure you want to remove this engagement?</div>
			<div className="btn-group">
				<div onClick={() => onClose(true)} className={clsx("engagement-button", "ft-eng-det-del-modal-yes-btn")}>
					Yes
				</div>
				<div onClick={() => onClose(false)} className={clsx("engagement-button", "ft-eng-det-del-modal-no-btn")}>
					No
				</div>
			</div>
		</Modal>
	);
};
