import clsx from "clsx";
import Modal from "react-responsive-modal";
import Button from "../../../common/button/Button";

export const UpdateModal = ({ show, onClose }) => {
	return (
		<Modal open={show} onClose={onClose} center>
			<div className="modal-title">Update Engagement?</div>
			<div className="modal-content">Are you sure you want to update this engagement?</div>

			<div className="modal-footer">
				<Button text="No" view="secondary" onClick={() => onClose(false)} className={"ft-eng-det-updateNoBtn"} />

				<Button text="Yes" onClick={() => onClose(true)} className={"ft-eng-det-updateYesBtn"} />
			</div>
		</Modal>
	);
};
