import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import PrivateRoute from "./components/common/PrivateRoute.js";
import Login from "./components/auth/login/Login.js";
import Main from "./components/layout/Main.js";
import SetPassword from "./components/auth/set_password/SetPassword.js";
import ResetPassword from "./components/auth/reset_password/ResetPassword.js";

import "react-responsive-modal/styles.css";
import "./App.css";
import "./components/styles/global.css";
import "react-responsive-modal/styles.css";

class App extends Component {
	render() {
		return (
			<Router>
				<div className="App">
					<Toaster position="top-center" reverseOrder={false} />

					<Switch>
						<Route exact path="/login" component={Login} />
						<Route exact path="/reset-password" component={ResetPassword} />
						<Route exact path="/set-password" component={SetPassword} />
						<PrivateRoute component={Main} path="/" />
					</Switch>
				</div>
			</Router>
		);
	}
}

export default App;
