// External Libraries
import React from "react";
import * as Icon from "react-feather";

// Services
import PlaidService from "../../../services/PlaidService";

// Common Components
import WComponent from "../../common/WComponent";

// Components
import ReconciliationTool from "./ReconciliationTool/ReconciliationTool";
import TransactionsTool from "./TransactionsTool/TransactionsTool";
import HstReconciliationTool from "./HstReconciliationTool/HstReconciliationTool";
import BookkeepingTool from "./BookkeepingTool/BookkeepingTool";

// Styling
import "./tools.css";
import ToolCard from "../../common/tool_card/ToolCard";

const TOOLS = {
	transactions: {
		type: "transactions",
		title: "Bank Records",
		description: "View transaction history for this client's bank records.",
		Icon: Icon.DollarSign
	},
	reconciliationTool: {
		type: "reconciliationTool",
		title: "Reconciliation Tool",
		description: "Helper tool to reconcile CRA entries and the General Ledger for a given client",
		Icon: Icon.RefreshCcw
	},
	gstReconciliationTool: {
		type: "gstReconciliationTool",
		title: "GST Reconciliation Tool",
		description: "Helper tool to reconcile GST entries and the General Ledger for a given client",
		Icon: Icon.RefreshCcw
	},
	bookkeeping: {
		type: "bookkeepingTool",
		title: "Bookkeeping Tool",
		description: "View and manage bookkeeping entries for this client.",
		Icon: Icon.BookOpen
	}
};

class Tools extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedTool: null,

			isBankAccountConnected: false
		};
	}

	async componentDidMount() {
		let { clientId } = this.props;

		let isBankAccountConnected = await PlaidService.isPlaidAccountConnected({ clientId });

		this.update({
			isBankAccountConnected
		});
	}

	onSelectTool = type => {
		let { isBankAccountConnected } = this.state;

		if (!isBankAccountConnected && type === TOOLS.transactions.type) {
			return;
		}

		this.update({
			selectedTool: type
		});
	};

	renderToolOption(toolOption) {
		let { isBankAccountConnected } = this.state;
		let { onSelectTool } = this;
		const disabled = !isBankAccountConnected && toolOption.type === TOOLS.transactions.type;

		return <ToolCard toolProps={toolOption} disabled={disabled} onClick={onSelectTool} />;
	}

	render() {
		let { clientId } = this.props;
		let { selectedTool } = this.state;

		const toolsOptionArray = Object.values(TOOLS);

		return (
			<>
				{!selectedTool && (
					<div className="container">
						<div className="container__body">
							<div className="tools">{toolsOptionArray.map(toolOption => this.renderToolOption(toolOption))}</div>
						</div>
					</div>
				)}

				{selectedTool === TOOLS.reconciliationTool.type && <ReconciliationTool clientId={clientId} />}
				{selectedTool === TOOLS.transactions.type && <TransactionsTool clientId={clientId} />}
				{selectedTool === TOOLS.gstReconciliationTool.type && <HstReconciliationTool clientId={clientId} />}
				{selectedTool === TOOLS.bookkeeping.type && <BookkeepingTool clientId={clientId} />}
			</>
		);
	}
}

export default Tools;
