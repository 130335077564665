import React, { Component } from "react";
import * as Icon from "react-feather";
import clsx from "clsx";

import "./checkbox.css";

class Checkbox extends Component {
	constructor(props) {
		super(props);
	}

	handleClick = event => {
		event.stopPropagation(); // Prevents triggering parent click
		const { onChange, checked, name } = this.props;

		// Call the provided onChange handler with a simulated event
		onChange({
			target: { checked: !checked, type: "checkbox", name }
		});
	};

	render() {
		let { containerClass, className, title, name, checked, isSmall, style } = this.props;

		return (
			<div
				className={clsx("checkbox", containerClass, {
					"checkbox--small": isSmall
				})}
				style={style}
				onClick={this.handleClick}
			>
				<div
					className={clsx("checkbox__input", className, {
						"checkbox__input--selected": checked,
						"checkbox__input--small": isSmall
					})}
					title={title}
					name={name}
					autoComplete="off"
				>
					{checked && <Icon.Check size={14} color="white" />}
				</div>

				{title && <div className="checkbox__title">{title}</div>}
			</div>
		);
	}
}

export default Checkbox;
