import React from "react";
import Modal from "react-responsive-modal";
import UtilityService from "../../../services/UtilityService";
import WComponent from "../../common/WComponent";

import "./support-ticket-modal.css";

import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import TextAreaField from "../../common/text_area_field/TextAreaField";
import Button from "../../common/button/Button";

const { alert } = UtilityService;

class SupportTicketModal extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			show: false,

			name: "",
			description: ""
		};
	}

	componentDidMount() {
		this.resetComponent();
	}

	componentDidUpdate(prevProps) {
		let { show } = this.props;

		if (prevProps.show !== show) {
			this.resetComponent();
		}
	}

	resetComponent = async () => {
		let { show } = this.props;

		await this.update({
			show,

			name: "",
			description: ""
		});
	};

	onClose = async () => {
		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSubmit = async () => {
		let { name, description } = this.state;

		if (!this.isValid()) {
			alert({ type: "error", text: "Missing Fields." });
			return;
		}

		let newTicket = await UtilityService.submitTicket({ name, description });

		if (newTicket) {
			alert({ type: "success", text: "Submitted new Support Ticket." });
		} else {
			alert({ type: "error", text: "Could not submit support ticket." });
		}

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	isValid() {
		let { name, description } = this.state;
		return name.length > 0 && description.length > 0;
	}

	render() {
		let { show, name, description } = this.state;
		let { onClose } = this.props;

		return (
			<Modal open={show} onClose={onClose} center classNames={{}}>
				<div className="stm">
					<div className="modal-title">Submit a Support Ticket ...</div>

					<div className="stm__body">
						<TextFieldInput
							containerClass="stm__body__input-c"
							title={"Title"}
							name="name"
							onChange={event => this.update({ name: event.target.value })}
							value={name}
							autoComplete="off"
							className="ft-submit-ticket-title"
						/>
						<TextAreaField
							title={"Description"}
							name="description"
							onChange={event => this.update({ description: event.target.value })}
							value={description}
							autoComplete="off"
							className="ft-submit-ticket-description"
						/>
					</div>
				</div>

				<div className="modal-footer">
					<Button view="secondary" text="Cancel" onClick={onClose} className="ft-submit-ticket-cancelbtn"/>
					<Button text="Submit" onClick={this.onSubmit} className="ft-submit-ticket-submitbtn"/>
				</div>
			</Modal>
		);
	}
}

export default SupportTicketModal;
