// External Libraries
import React from "react";

// Services
import ReconciliationService from "../../../../services/ReconciliationService";
import UtilityService from "../../../../services/UtilityService";

// Common Components
import WComponent from "../../../common/WComponent";
import Loader from "../../../common/loader/Loader";

import Button from "../../../common/button/Button";
import DateSelector from "../../../common/date_selector/DateSelector";

// Components
import ReconSummary from "./ReconciliationSummary";
import ReconTable from "./ReconciliationTable";

// Styling
import "./reconciliation-tool.css";

const { alert } = UtilityService;

class ReconciliationTool extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// Input State
			yearEnd: new Date(),
			files: [],

			// Data State
			reconciliationData: [],
			filteredReconciliationData: [],
			currentYearSummary: [],

			// UI State
			loading: false,
			isReconciliationComplete: false
		};
	}

	onFileChange = async e => {
		const selectedFiles = Array.from(e.target.files);
		await this.update({
			files: selectedFiles
		});
	};

	onReconcileClicked = async e => {
		e.preventDefault();

		let { files } = this.state;

		// Return early if we don't have any valid files
		if (!this.hasValidFiles()) {
			alert({ type: "info", text: "No files have been added." });
			return;
		}

		// Update loading state
		await this.update({ loading: true });

		// Upload files and reconcile data on the backend
		let { response: reconciliationData, error } = await ReconciliationService.reconcileFiles({ files });

		if (error) {
			// alert
			return;
		}

		await this.update({ reconciliationData });

		// Compute any required filtering for UI
		await this.computeFiltering();

		// Hide the loading and indicate to the state that the reconciliation is complete
		await this.update({ isReconciliationComplete: true, loading: false });
	};

	async computeFiltering() {
		let { reconciliationData, yearEnd } = this.state;

		let filteredReconciliationData = await ReconciliationService.filterReconciliationData({ reconciliationData, yearEndDate: yearEnd });
		let { cyObj: currentYearSummary } = filteredReconciliationData;

		await this.update({
			filteredReconciliationData,
			currentYearSummary
		});
	}

	onYearEndChange = async yearEnd => {
		await this.update({ yearEnd });
		await this.computeFiltering();
	};

	hasValidFiles = () => {
		let { files } = this.state;
		return files.length === 2;
	};
	renderReconcileButton() {
		let { loading } = this.state; // Get loading state
		let isDisabled = !this.hasValidFiles() || loading; // Determine if button should be disabled

		return <Button text="Reconcile" onClick={this.onReconcileClicked} disabled={isDisabled} />;
	}
	renderFiles() {
		let { files } = this.state;

		if (!files || files.length === 0) {
			return <div className="recon-tool__actions__file-list recon-tool__actions__file-list--empty">No files have been selected</div>;
		}

		return (
			<div className="recon-tool__actions__file-list">
				{files.map((file, index) => {
					return (
						<div key={index} className="recon-tool__actions__file-list__item">
							{file.name}
						</div>
					);
				})}
			</div>
		);
	}

	renderFileUpload() {
		let { isReconciliationComplete } = this.state;

		if (isReconciliationComplete) {
			return;
		}

		return (
			<div className="recon-tool__actions">
				<label className="btn btn--file" for="file-upload">
					Add files
					<input id="file-upload" type="file" onChange={this.onFileChange} multiple />
				</label>

				{this.renderFiles()}
				{this.renderReconcileButton()}
			</div>
		);
	}

	renderYearEnd() {
		let { yearEnd, isReconciliationComplete } = this.state;
		const { onYearEndChange } = this;

		if (!isReconciliationComplete) {
			return;
		}

		return (
			<DateSelector
				containerClass="recon-tool__year-end-picker"
				title="Year End"
				selectedDate={yearEnd !== "" ? new Date(yearEnd) : new Date()}
				onDateSelect={onYearEndChange}
			/>
		);
	}

	render() {
		let { clientId } = this.props;
		let { filteredReconciliationData, currentYearSummary, loading } = this.state;

		return (
			<>
				<div className="container">
					<div className="container-header-title">
						<h1 className="container-header-title__name">Reconciliation Tool</h1>
					</div>

					{this.renderFileUpload()}

					{this.renderYearEnd()}

					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}

					{currentYearSummary.length > 0 && <ReconTable currentYearSummary={currentYearSummary} />}
					{currentYearSummary.length > 0 && <ReconSummary filteredReconciliationData={filteredReconciliationData} clientId={clientId} />}
				</div>
			</>
		);
	}
}

export default ReconciliationTool;
