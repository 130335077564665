// External Libraries
import React from "react";
import DatePicker from "react-datepicker";
import moment from "moment";
import { toast as alert } from "react-toastify";
import * as Icon from "react-feather";

// Services
import BookkeepingService from "../../../../services/BookkeepingService";
import PlaidService from "../../../../services/PlaidService";

// Common Components
import WComponent from "../../../common/WComponent";
import ConfigurationService from "../../../../services/ConfigurationService";
import Loader from "../../../common/loader/Loader";
import Button from "../../../common/button/Button";
import DateSelector from "../../../common/date_selector/DateSelector";
import Checkbox from "../../../common/checkbox/Checkbox";

// Components
import BookkeepingTable from "./BookkeepingTable";

// Styling
import "./bookkeeping-tool.css";

class BookkeepingTool extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// Input State
			files: [],
			endDate: new Date(),
			startDate: new Date(),

			// Data State
			connectedAccounts: [],
			transactions: [],

			// UI State
			loading: false,
			isBankingComplete: false,

			// Client Data
			isSelected: false
		};
	}

	onGenerateBookkeepingClicked = async e => {
		let { clientId } = this.props;
		let { endDate, startDate, connectedAccounts, files } = this.state;

		e.preventDefault();

		const formattedStartDate = moment(startDate).format("YYYY-MM-DD");
		const formattedEndDate = moment(endDate).format("YYYY-MM-DD");

		await this.update({
			loading: true
		});

		try {
			let allTransactions = {
				accounts: [],
				transactions: []
			};

			if (files.length > 0) {
				for (let file of files) {
					let { transactions } = await BookkeepingService.extractTransactionsFromCsv({ file });
					allTransactions.transactions.push(...transactions);
				}
			} else {
				for (let account of connectedAccounts) {
					if (account.isChecked) {
						let { accounts, transactions } = await PlaidService.fetchAccountsAndTransactions({
							startDate: formattedStartDate,
							endDate: formattedEndDate,
							plaidAccountId: account.id,
							clientId
						});

						allTransactions.accounts.push(accounts[0]);
						allTransactions.transactions.push(...transactions);
					}
				}
			}

			let accountMap = allTransactions.accounts.reduce((acc, account) => {
				acc[account.account_id] = account.name;
				return acc;
			}, {});

			let transactions = allTransactions.transactions.map(transaction => {
				let date = transaction.date;
				let description = transaction.name || transaction.description || "";
				let merchant = transaction.merchant_name;
				let amount = transaction.amount;
				let accountId = transaction.account_id;
				let accountName = accountMap[accountId] || transaction.bankAccountName || "";

				return {
					date,
					description,
					merchant,
					amount,
					accountName,
					selected: false,
					classification: null,
					tax: ""
				};
			});

			await this.update({
				transactions: transactions,
				loading: false,
				isBankingComplete: true
			});
		} catch (error) {
			console.error("Error fetching transactions:", error);
		}
		await this.update({
			loading: false
		});
	};

	onUpload = async e => {
		const selectedFiles = Array.from(e.target.files);
		let { clientId } = this.props;

		await this.update({
			loading: true,
			files: selectedFiles
		});

		await this.update({
			loading: false
		});
	};

	componentDidMount = async () => {
		let { clientId } = this.props;

		let result = await PlaidService.fetchAccounts({ searchTerm: "", clientId });

		let bankAccounts = result.map(account => {
			return {
				id: account.id,
				title: account.name,
				name: account.name,
				isChecked: false
			};
		});

		await this.update({
			connectedAccounts: bankAccounts
		});
	};

	onStartDateChange = async startDate => {
		await this.update({ startDate });
	};

	onEndDateChange = async endDate => {
		await this.update({ endDate });
	};

	checkBoxSelected = async index => {
		let { connectedAccounts } = this.state;
		connectedAccounts[index].isChecked = !connectedAccounts[index].isChecked;
		await this.update({ connectedAccounts });
	};

	onTransactionsUpdate = async updatedTransactions => {
		await this.update({ transactions: updatedTransactions });
	};

	renderAccounts() {
		const { isBankingComplete, startDate, endDate, connectedAccounts } = this.state;

		if (isBankingComplete) {
			return null;
		}

		return (
			<div className="container">
				<div className="container-header-title">
					<h1 className="container-header-title__name">Bookkeeping Tool</h1>
					<p>Select accounts to include in the bookkeeping:</p>
				</div>

				<div className="bookkeeping-tool__body">
					{connectedAccounts.map((account, index) => (
						<Checkbox
							key={index}
							title={account.title}
							name={account.name}
							checked={account.isChecked}
							onChange={() => this.checkBoxSelected(index)}
						/>
					))}

					<DateSelector title="Start Date" selectedDate={startDate ? new Date(startDate) : undefined} onDateSelect={this.onStartDateChange} />
					<DateSelector title="End Date" selectedDate={endDate ? new Date(endDate) : undefined} onDateSelect={this.onEndDateChange} />
					<Button text="Generate Bookkeeping" onClick={this.onGenerateBookkeepingClicked} />
					<label>
						<Button text="Import Bank Statements" type="file" view="secondary" icon={<Icon.Upload size={18} />} />
						<input type="file" multiple onChange={this.onUpload} style={{ display: "none" }} />
					</label>
				</div>
			</div>
		);
	}

	renderFiles() {
		let { files, transactions } = this.state;

		if (transactions.length > 0) {
			return null;
		}

		if (!files || files.length === 0) {
			return (
				<div>
					<i>
						To import bank statement transactions from a CSV instead, click on <b>'Import Bank Statements'</b> with 3 columns: <b>Date</b>,{" "}
						<b>Description</b>, <b>Amount</b>
						<br />
						Please use the trial balance bank account name as the CSV file name.
						<br />
					</i>
				</div>
			);
		}

		return (
			<div className="bookkeeping-tool__actions__file-list">
				{files.map((file, index) => (
					<div key={index} className="bookkeeping-tool__actions__file-list__item">
						{file.name}
					</div>
				))}
				<br />
			</div>
		);
	}

	render() {
		const { loading, transactions } = this.state;
		const { clientId } = this.props;

		if (loading) {
			return (
				<div className="container-loader">
					<Loader />
				</div>
			);
		}

		return (
			<div>
				{this.renderAccounts()}
				<div className="container">
					{this.renderFiles()}
					{transactions.length > 0 && (
						<BookkeepingTable clientId={clientId} transactions={transactions} onTransactionsUpdate={this.onTransactionsUpdate} />
					)}
				</div>
			</div>
		);
	}
}

export default BookkeepingTool;
