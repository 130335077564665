import React, { Component } from "react";
import "./scrollable-container.css";

class ScrollableContainer extends Component {
	constructor(props) {
		super(props);
		this.topScrollbarRef = React.createRef();
		this.bottomScrollbarRef = React.createRef();
	}

	handleTopScroll = () => {
		if (this.bottomScrollbarRef.current) {
			this.bottomScrollbarRef.current.scrollLeft = this.topScrollbarRef.current.scrollLeft;
		}
	};

	handleBottomScroll = () => {
		if (this.topScrollbarRef.current) {
			this.topScrollbarRef.current.scrollLeft = this.bottomScrollbarRef.current.scrollLeft;
		}
	};

	render() {
		const { children } = this.props;
		return (
			<div className="scrollable-container">
				{/* Top Scrollbar */}
				<div className="scrollable-container__top" ref={this.topScrollbarRef} onScroll={this.handleTopScroll}>
					<div className="scrollable-container__top__content"></div>
				</div>

				{/* Bottom Scrollable Content */}
				<div className="scrollable-container__bottom" ref={this.bottomScrollbarRef} onScroll={this.handleBottomScroll}>
					{children}
				</div>
			</div>
		);
	}
}

export default ScrollableContainer;
