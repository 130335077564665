import UserService from "./UserService";
import UtilityService from "./UtilityService";
import Kichiri from "./KichiriService";

import moment from "moment";
import ConfigurationService from "./ConfigurationService";
import ClientService from "./ClientService";

const BookkeepingService = {
	/**
	 * Downloads bookkeeping summaries as an Excel file for a specific client.
	 *
	 * @async
	 * @function downloadBookkeepingSummaries
	 * @param {Object} params - Parameters for downloading bookkeeping summaries.
	 * @param {string} params.clientId - The ID of the client for whom the summaries are generated.
	 * @param {Array<Object>} params.transactions - The list of transactions to be included in the summary.
	 * @param {Object} params.priorYearTrialBalance - The trial balance data for the prior year.
	 * @param {Array<string>} params.classifications - The classifications to be applied to the transactions.
	 * @returns {Promise<void>} A promise that resolves when the file is successfully downloaded.
	 * @throws {Error} Throws an error if the fetch request or file download fails.
	 */
	async downloadBookkeepingSummaries({ clientId, transactions, priorYearTrialBalance, classifications, hasTax }) {
		let user = UserService.getUserData();

		try {
			let { client } = await ClientService.fetchClient({ clientId });

			let response = await fetch(`${ConfigurationService.resolveApi().rest}/tools/bookkeeping/download_summaries`, {
				method: "POST",
				headers: {
					authorization: user.authToken,
					"Content-Type": "application/json"
				},
				body: JSON.stringify({
					clientId,
					transactions,
					priorYearTrialBalance,
					classifications,
					hasTax
				})
			});

			let blob = await response.blob();

			UtilityService.downloadFile({ blob, fileName: `${client.name} Summary.xlsx` });
		} catch (error) {}
	},

	/**
	 * Categorizes a financial transaction by sending data to the bookkeeping classification API.
	 *
	 * @async
	 * @function categorizeTransaction
	 * @param {Object} params - Parameters for the transaction classification.
	 * @param {string} params.clientId - The ID of the client associated with the transaction.
	 * @param {string} params.transactionDescription - A description of the transaction.
	 * @param {number} params.amount - The amount of the transaction.
	 * @param {Array<string>} params.classifications - The classifications for the transaction.
	 * @param {string} params.industry - The industry related to the transaction.
	 * @param {string} params.province - The province where the transaction occurred.
	 * @returns {Promise<Object>} A promise that resolves to the API response as a JSON object.
	 * @throws {Error} Throws an error if the fetch request fails.
	 */
	async categorizeTransaction({ clientId, transactionDescription, amount, classifications, industry, province }) {
		let userData = UserService.getUserData();

		try {
			let response = await Kichiri.tools.classifyBookkeepingTransactions(
				{},
				{
					clientId,
					transactionDescription,
					amount,
					classifications,
					industry,
					province
				},
				userData.authToken
			);

			response = response.data;

			return await response;
		} catch (error) {}
	},

	/**
	 * Uploads Caseware reference files for a specific client.
	 *
	 * @param {Object} params - The parameters for the upload.
	 * @param {string} params.clientId - The ID of the client.
	 * @param {File[]} params.files - The files to be uploaded.
	 * @returns {Promise<Object>} The response from the server.
	 * @throws Will throw an error if the upload fails.
	 */
	async uploadCasewareReference({ clientId, files }) {
		let userData = UserService.getUserData();

		try {
			let { client } = await ClientService.fetchClient({ clientId });

			let formData = new FormData();

			for (let file of files) {
				formData.append("files", file);
			}

			let response = await fetch(`${ConfigurationService.resolveApi().rest}/tools/bookkeeping/upload_caseware_reference`, {
				method: "POST",
				body: formData,
				headers: {
					authorization: userData.authToken,
					Accept: "application/json"
				}
			});

			return await response.json();
		} catch (error) {}
	},

	/**
	 * Calculates the tax amount based on the given amount and tax rate.
	 *
	 * @param {Object} params - The parameters for the calculation.
	 * @param {number} params.amount - The amount on which tax is to be calculated.
	 * @param {string} params.tax - The tax rate as a string (e.g., "5%" or "10.5%").
	 * @returns {string} The calculated tax amount as a string with two decimal places.
	 */
	calculateTaxAmount({ amount, tax, hasTax }) {
		if (!hasTax) return 0.0;
		if (!tax) return 0.0;
		let taxRate = parseFloat(tax.match(/[\d.]+/)[0]); // Extract digits from the tax string
		let taxAmount = (amount * taxRate) / (100 + taxRate);
		return parseFloat(taxAmount.toFixed(2));
	},

	/**
	 * Extracts transactions from a CSV file.
	 *
	 * @param {Object} params - The parameters object.
	 * @param {File} params.file - The CSV file to extract transactions from.
	 * @returns {Promise<Object>} A promise that resolves to an object containing the extracted transactions.
	 */
	async extractTransactionsFromCsv({ file }) {
		let { name } = file;
		name = name.replace(".csv", "");
		let fileContent = await file.text();
		let lines = fileContent.split("\n");
		let cleanedLines = this.cleanFileArray({ lines });

		const extractedData = cleanedLines.map(item => this.parseTransaction(item));

		let transactions = extractedData.map(transaction => {
			let date = transaction.date;
			let description = transaction.description;
			let merchant = transaction.merchant;
			let amount = transaction.amount;
			let bankAccountName = name;

			return {
				date,
				description,
				merchant,
				amount,
				bankAccountName
			};
		});

		return {
			transactions
		};
	},

	/**
	 * Cleans the given item by trimming whitespace and removing double quotes.
	 *
	 * @param {string} item - The item to be cleaned.
	 * @returns {string} The cleaned item.
	 */
	cleanItem(item) {
		return item.trim().replace(/"/g, "");
	},

	/**
	 * Splits a cleaned item string into its date description and amount parts.
	 *
	 * @param {string} cleanedItem - The cleaned item string to be split.
	 * @returns {Object} An object containing the date description and amount.
	 * @returns {string} return.dateDescription - The date and description part of the item.
	 * @returns {string} return.amount - The amount part of the item.
	 */
	splitParts(cleanedItem) {
		const parts = cleanedItem.split(/,(?=\$|-)/);
		const [dateDescription, amount] = parts;
		return { dateDescription, amount };
	},

	/**
	 * Extracts the date and description from a comma-separated string.
	 *
	 * @param {string} dateDescription - A string containing the date and description separated by a comma.
	 * @returns {{ date: string, description: string }} An object containing the extracted date and description.
	 */
	extractDateDescription(dateDescription) {
		const [date, description] = dateDescription.split(",");
		return { date, description };
	},

	/**
	 * Parses a transaction item and extracts its date, description, and amount.
	 *
	 * @param {Object} item - The transaction item to be parsed.
	 * @returns {Object} An object containing the parsed date, description, and amount.
	 * @returns {string} return.date - The extracted date from the transaction item.
	 * @returns {string} return.description - The extracted description from the transaction item.
	 * @returns {number} return.amount - The extracted and cleaned amount from the transaction item.
	 */
	parseTransaction(item) {
		const cleanedItem = this.cleanItem(item);
		const { dateDescription, amount } = this.splitParts(cleanedItem);
		const { date, description } = this.extractDateDescription(dateDescription);
		const merchant = "";

		return {
			date,
			description,
			merchant,
			amount: parseFloat(amount.replace(/[$,]/g, ""))
		};
	},

	/**
	 * Cleans an array of lines by removing empty lines and lines that match a specific pattern.
	 *
	 * @param {Object} param - The parameter object.
	 * @param {string[]} param.lines - The array of lines to be cleaned.
	 * @returns {string[]} The cleaned array of lines.
	 *
	 */
	cleanFileArray({ lines }) {
		let cleanedLines = lines.filter(line => {
			return line !== `,,\r` && line !== "";
		});

		return cleanedLines;
	}
};

export default BookkeepingService;
