// External Libraries
import React from "react";
import { Check, ChevronDown } from "lucide-react";
import * as Icon from "react-feather";
import clsx from "clsx";

// Services
import BookkeepingService from "../../../../services/BookkeepingService";
import ClientService from "../../../../services/ClientService";

// Common Components
import WComponent from "../../../common/WComponent";
import SearchInput from "../../../common/search_input/SearchInput";
import Button from "../../../common/button/Button";
import Loader from "../../../common/loader/Loader";
import Checkbox from "../../../common/checkbox/Checkbox";
import SearchableDropdown from "../../../common/searchable_dropdown/SearchableDropdown";
import TextFieldInput from "../../../common/text_field_input/TextFieldInput";
import ClassificationDropdown from "./ClassificationDropdown";

// Styling
import "./bookkeeping-tool.css";

class BookkeepingTable extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			// Input State
			files: [],
			industry: "",
			province: "",

			// Data State
			selectAll: false,
			activeClassificationDropdown: null,
			bulkClassificationDropdownOpen: false,
			search: "",
			classifications: [
				{ name: "Accounts receivable (A/R)", classification: "Balance Sheet" },
				{ name: "Current assets", classification: "Balance Sheet" },
				{ name: "Non current assets", classification: "Balance Sheet" },
				{ name: "Cash and cash equivalents", classification: "Balance Sheet" },
				{ name: "Inventory", classification: "Balance Sheet" },
				{ name: "Prepaid expenses", classification: "Balance Sheet" },
				{ name: "Property, plant, and equipment (PP&E)", classification: "Balance Sheet" },
				{ name: "Long-term assets", classification: "Balance Sheet" },
				{ name: "Accounts payable (A/P)", classification: "Balance Sheet" },
				{ name: "Current liabilities", classification: "Balance Sheet" },
				{ name: "Long-term liabilities", classification: "Balance Sheet" },
				{ name: "Equity", classification: "Balance Sheet" },
				{ name: "Retained earnings", classification: "Balance Sheet" },
				{ name: "Advertising", classification: "Income" },
				{ name: "Auto", classification: "Income" },
				{ name: "Bad debts", classification: "Income" },
				{ name: "Bank charges", classification: "Income" },
				{ name: "Donations", classification: "Income" },
				{ name: "Cost of Goods Sold", classification: "Income" },
				{ name: "Cost of Labour", classification: "Income" },
				{ name: "Distribution costs", classification: "Income" },
				{ name: "Dues and subscriptions", classification: "Income" },
				{ name: "Dividends", classification: "Income" },
				{ name: "Entertainment", classification: "Income" },
				{ name: "Equipment rental", classification: "Income" },
				{ name: "Equipment rental - COS", classification: "Income" },
				{ name: "Insurance", classification: "Income" },
				{ name: "Interest paid", classification: "Income" },
				{ name: "Legal and professional fees", classification: "Income" },
				{ name: "Meals and entertainment", classification: "Income" },
				{ name: "Office/General Administrative Income", classification: "Income" },
				{ name: "Other Miscellaneous Service Costs", classification: "Income" },
				{ name: "Payroll Expenses", classification: "Income" },
				{ name: "Promotional Meals", classification: "Income" },
				{ name: "Rent or Lease of Buildings", classification: "Income" },
				{ name: "Sales of Product Income", classification: "Income" },
				{ name: "Service/Fee Income", classification: "Income" },
				{ name: "Repairs and Maintenance", classification: "Income" },
				{ name: "Shipping, Freight, and Delivery", classification: "Income" },
				{ name: "Subscriptions", classification: "Income" },
				{ name: "Supplies", classification: "Income" },
				{ name: "Taxes", classification: "Income" },
				{ name: "Travel", classification: "Income" },
				{ name: "Utilities", classification: "Income" },
				{ name: "Uncategorized", classification: "Income" }
			],
			priorYearTrialBalance: [],
			hasTax: false,
			bookkeepingTableHeadings: [
				{ name: "Date" },
				{ name: "Description" },
				{ name: "Merchant" },
				{ name: "Amount" },
				{ name: "Tax" },
				{ name: "Tax Amount" },
				{ name: "Classification" },
				{ name: "Bank" }
			],

			// UI State
			loading: false,
			sortField: null,
			sortOrder: "asc"
		};
	}

	async componentDidMount() {
		let { clientId, bankAccountName } = this.props;
	}

	handleSelectAll = async event => {
		const { checked } = event.target;
		const { transactions, onTransactionsUpdate } = this.props;

		const filteredTransactions = this.getFilteredTransactions();
		const filteredIndexes = this.getFilteredIndexes(filteredTransactions);

		const updatedTransactions = transactions.map((transaction, index) => ({
			...transaction,
			selected: filteredIndexes.has(index) ? checked : transaction.selected
		}));

		await this.update({
			selectAll: checked
		});

		onTransactionsUpdate(updatedTransactions);
	};

	handleSelectRow = async index => {
		const { transactions, onTransactionsUpdate } = this.props;

		const updatedTransactions = transactions.map((transaction, i) => {
			const newSelected = i === index ? !transaction.selected : transaction.selected;

			return {
				...transaction,
				selected: newSelected
			};
		});

		const allSelected = updatedTransactions.every(transaction => transaction.selected);

		await this.update({
			selectAll: allSelected
		});

		onTransactionsUpdate(updatedTransactions);
	};

	handleClassificationClick = async (index, event) => {
		const { activeClassificationDropdown } = this.state;

		event.stopPropagation();

		const newActiveClassification = activeClassificationDropdown === index ? null : index;

		await this.update({
			activeClassificationDropdown: newActiveClassification
		});
	};

	handleClassificationChange = async (index, newClassification) => {
		const { transactions, onTransactionsUpdate } = this.props;

		const updatedTransactions = transactions.map((transaction, i) => (i === index ? { ...transaction, classification: newClassification } : transaction));

		await this.update({
			activeClassificationDropdown: null
		});

		onTransactionsUpdate(updatedTransactions);
	};

	handleBulkClassificationClick = async event => {
		event.stopPropagation();

		await this.update({
			bulkClassificationDropdownOpen: !this.state.bulkClassificationDropdownOpen
		});
	};

	handleBulkClassificationChange = async newClassification => {
		const { transactions, onTransactionsUpdate } = this.props;

		const filteredTransactions = this.getFilteredTransactions();
		const filteredIndexes = this.getFilteredIndexes(filteredTransactions);

		const updatedTransactions = transactions.map((transaction, index) =>
			filteredIndexes.has(index) && transaction.selected ? { ...transaction, classification: newClassification } : transaction
		);

		await this.update({
			bulkClassificationDropdownOpen: false
		});

		onTransactionsUpdate(updatedTransactions);
	};

	handleClickOutside = async () => {
		await this.update({
			activeClassificationDropdown: null,
			bulkClassificationDropdownOpen: false
		});
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});
	};

	getFilteredTransactions = () => {
		const { search } = this.state;
		const { transactions } = this.props;

		if (!search) return transactions;

		const searchTerm = search.toLowerCase();

		return transactions.filter(
			transaction => transaction.description.toLowerCase().includes(searchTerm) || (transaction.merchant || "").toLowerCase().includes(searchTerm)
		);
	};

	getFilteredIndexes = filteredTransactions => {
		const { transactions } = this.props;

		return new Set(filteredTransactions.map(filteredTransaction => transactions.findIndex(transaction => transaction === filteredTransaction)));
	};

	onExport = async e => {
		let { clientId, transactions } = this.props;
		let { priorYearTrialBalance, classifications, hasTax } = this.state;

		e.preventDefault();

		await this.update({
			loading: true
		});

		let filteredTransactions = transactions.map(transaction => {
			let { date, description, merchant, amount, classification, accountName, tax } = transaction;

			let taxAmount = BookkeepingService.calculateTaxAmount({ amount, tax, hasTax });

			return { date, description, merchant, amount, classification, accountName, taxAmount };
		});

		priorYearTrialBalance = priorYearTrialBalance ? priorYearTrialBalance : [];

		await BookkeepingService.downloadBookkeepingSummaries({
			clientId,
			transactions: filteredTransactions,
			priorYearTrialBalance,
			classifications,
			hasTax
		});

		await this.update({
			loading: false
		});
	};

	onClassify = async e => {
		let { clientId, transactions, onTransactionsUpdate } = this.props;

		let { classifications, industry, province } = this.state;

		e.preventDefault();

		await this.update({
			loading: true
		});

		try {
			const classificationPromises = transactions.map(transaction =>
				BookkeepingService.categorizeTransaction({
					clientId,
					transactionDescription: transaction.description + " - " + transaction.merchant,
					amount: transaction.amount,
					classifications,
					industry,
					province
				})
			);
			const openAiClassifications = await Promise.all(classificationPromises);

			const updatedTransactions = transactions.map((transaction, index) => ({
				...transaction,
				classification: openAiClassifications[index].classification || "Unclassified",
				tax: openAiClassifications[index].tax || ""
			}));

			onTransactionsUpdate(updatedTransactions);
		} catch (error) {
			console.error("Classification failed:", error);
		}

		await this.update({
			loading: false
		});
	};

	onUpload = async e => {
		const selectedFiles = Array.from(e.target.files);
		let { clientId } = this.props;

		await this.update({
			loading: true,
			files: selectedFiles
		});

		try {
			const response = await BookkeepingService.uploadCasewareReference({ clientId, files: selectedFiles });

			const priorYearTrialBalanceExtract = response.map(account => ({
				accountNumber: account.accountNumber,
				accountType: account.accountType,
				accountClass: account.accountClass,
				accountDescription: account.accountDescription,
				priorYearAmount: account.priorYearAmount
			}));

			// Only update classifications if there are valid ones from the upload
			const uploadedClassifications = response
				.filter(account => account.accountNumber && account.accountDescription)
				.map(account => ({
					name: `${account.accountNumber} - ${account.accountDescription}`
				}));

			await this.update({
				classifications: uploadedClassifications.length > 0 ? uploadedClassifications : this.state.classifications,
				priorYearTrialBalance: priorYearTrialBalanceExtract
			});
		} catch (error) {
			console.error("Upload failed:", error);
		}

		await this.update({
			loading: false
		});
	};

	getSortedClassifications = () => {
		return [...this.state.classifications].sort((a, b) => a.name.localeCompare(b.name, undefined, { sensitivity: "base" }));
	};

	checkBoxSelected = async () => {
		await this.update({
			hasTax: !this.state.hasTax
		});
		console.log(this.state.hasTax);
	};

	onChange = async event => {
		await this.update({
			[event.target.name]: event.target.value
		});
	};

	onProvinceSelect = async province => {
		await this.update({
			province: province.name
		});
	};

	calculateTaxAmount = ({ amount, tax }) => {
		let { hasTax } = this.state;

		if (!hasTax) return 0.0;

		let taxAmount = BookkeepingService.calculateTaxAmount({ amount, tax, hasTax });

		return taxAmount;
	};

	sortBy = async field => {
		let { sortOrder } = this.state;

		await this.update({
			sortField: field,
			sortOrder: sortOrder === "asc" ? "desc" : "asc"
		});

		this.sortTransactions();
	};

	sortTransactions = () => {
		const { sortField, sortOrder } = this.state;
		const { transactions, onTransactionsUpdate } = this.props;

		if (!sortField) return;

		const sortedTransactions = [...transactions].sort((a, b) => {
			if (a[sortField] < b[sortField]) return sortOrder === "asc" ? -1 : 1;
			if (a[sortField] > b[sortField]) return sortOrder === "asc" ? 1 : -1;
			return 0;
		});

		onTransactionsUpdate(sortedTransactions);
	};

	renderSortIcon = field => {
		let { sortField, sortOrder } = this.state;

		return sortField === field && (sortOrder === "asc" ? <Icon.ChevronUp size={14} /> : <Icon.ChevronDown size={14} />);
	};

	renderClientHeadings = () => {
		const { bulkClassificationDropdownOpen, search, hasTax, industry } = this.state;

		const sortedClassifications = this.getSortedClassifications();

		const {
			checkBoxSelected,
			handleBulkClassificationClick,
			handleBulkClassificationChange,
			onChange,
			onExport,
			onProvinceSelect,
			onSearch,
			onUpload
		} = this;

		return (
			<div className="clients__header">
				<TextFieldInput title="Industry" name="industry" onChange={onChange} value={industry} autoComplete="off" />

				<SearchableDropdown title="Select a province" type={"provinces"} onSelect={onProvinceSelect} firstFocus showAll />

				<div className="tax-checkbox">
					<Checkbox title="Taxes applicable" checked={hasTax} onChange={checkBoxSelected} />
				</div>
				<div className="classification-wrapper">
					<Button text="Classifications" view="secondary" onClick={handleBulkClassificationClick} icon={<Icon.ChevronsDown size={18} />} />

					{bulkClassificationDropdownOpen && (
						<div className="classification-dropdown">
							{sortedClassifications.map(classification => (
								<div key={classification.name} className={`dropdown-item`} onClick={() => handleBulkClassificationChange(classification.name)}>
									{classification.name}
								</div>
							))}
						</div>
					)}
				</div>

				{this.renderClassifyButton()}

				<label>
					<Button text="Upload CW References" type="file" view="secondary" icon={<Icon.UploadCloud size={18} />} />
					<input type="file" multiple onChange={onUpload} style={{ display: "none" }} />
				</label>
				<Button view="secondary" onClick={onExport} icon={<Icon.DownloadCloud size={18} />} />
				<SearchInput placeholder="Search Transactions..." onChange={onSearch} value={search} />
			</div>
		);
	};

	renderClassifyButton() {
		const { industry, province } = this.state;
		const { transactions } = this.props;

		const isDisabled = !province || !industry || !transactions || transactions.length === 0;

		return (
			<Button
				text="Automatically Classify"
				view="secondary"
				onClick={isDisabled ? null : this.onClassify}
				icon={<Icon.Send size={18} />}
				disabled={isDisabled}
			/>
		);
	}

	renderFiles() {
		let { files } = this.state;

		if (!files || files.length === 0) {
			return (
				<div>
					<br />
					To use CW references, upload the following files ending with **am.dbf and **bl.dbf - These are usually found in 'C:\Program Files
					(x86)\CaseWare\Data\ClientName'{" "}
				</div>
			);
		}

		return (
			<div className="bookkeeping-tool__actions__file-list">
				{files.map((file, index) => (
					<div key={index} className="bookkeeping-tool__actions__file-list__item">
						{file.name}
					</div>
				))}
			</div>
		);
	}

	render() {
		const { activeClassificationDropdown, loading, selectAll, bookkeepingTableHeadings } = this.state;
		const { clientId } = this.props;
		const sortedClassifications = this.getSortedClassifications();
		const filteredTransactions = this.getFilteredTransactions();

		return (
			<div className="bookkeeping-tables">
				{loading && (
					<div className="container-loader">
						<Loader />
					</div>
				)}

				{this.renderClientHeadings()}

				{this.renderFiles()}

				<div className="scroll-container">
					<div className={"t"}>
						<div className={"t__header"}>
							<div className="t__header__item">
								<label className={clsx("checkbox", "checkbox--small", "engs-row-item--checkbox")}>
									<input type="checkbox" checked={selectAll} onChange={this.handleSelectAll} />
								</label>
							</div>
							{bookkeepingTableHeadings.map((heading, index) => (
								<div key={index} className="t__header__item" onClick={() => this.sortBy(heading.name.toLowerCase())}>
									{heading.name}
									{this.renderSortIcon(heading.name.toLowerCase())}
								</div>
							))}
						</div>

						{filteredTransactions.map((transaction, index) => {
							{
								let { date, description, merchant, amount, tax, classification, selected, accountName } = transaction;
								let odd = index % 2 === 0;

								return (
									<div className={clsx("t__row", { "t__row--stripe": odd })} key={index}>
										<div className="t__row__item">
											<label className={clsx("checkbox", "checkbox--small", "engs-row-item--checkbox")}>
												<input type="checkbox" checked={selected} onChange={() => this.handleSelectRow(index)} />
											</label>
										</div>
										<div className="t__row__item">{date}</div>
										<div className="t__row__item">{description}</div>
										<div className="t__row__item">{merchant}</div>
										<div className="t__row__item">{amount.toFixed(2)}</div>
										<div className="t__row__item">{tax || ""}</div>
										<div className="t__row__item">{this.calculateTaxAmount({ amount, tax })}</div>
										<div className="t__row__item">
											<ClassificationDropdown
												index={index}
												classification={classification}
												activeClassificationDropdown={activeClassificationDropdown}
												sortedClassifications={sortedClassifications}
												handleClassificationClick={this.handleClassificationClick}
												handleClassificationChange={this.handleClassificationChange}
											/>
										</div>
										<div className="t__row__item">{accountName}</div>
									</div>
								);
							}
						})}
					</div>
				</div>
			</div>
		);
	}
}

export default BookkeepingTable;
