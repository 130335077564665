import UserService from "./UserService";
import Kichiri from "./KichiriService";
import { STATUSES } from "../constants/Tasks";

const TaskService = {
	/**
	 * Fetch a task
	 * @param {Number} taskId
	 * @returns {Object}
	 */
	async fetchTask({ taskId }) {
		let { authToken } = UserService.getUserData();

		try {
			let { data } = await Kichiri.task.get(
				{
					taskId
				},
				{},
				authToken
			);

			return data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Update a task
	 * @param {Number} taskId
	 * @param {String} name
	 * @param {Number} engagementId
	 * @param {Number} assignedUserId
	 * @param {Number} clientId
	 * @param {Number} clientId
	 * @param {String} description
	 * @param {Date} deadline
	 * @param {String} notes
	 * @param {String} status
	 * @param {Boolean} notify
	 */
	async update({ taskId, name, engagementId, assignedUsers, clientId, description, deadline, notes, status, notify, isDeleted }) {
		let { authToken } = UserService.getUserData();

		try {
			let response = await Kichiri.task.update(
				{
					taskId,
					name,
					engagementId,
					assignedUsers,
					clientId,
					description,
					deadline,
					notes,
					status,
					notify,
					isDeleted
				},
				{},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return null;
	},

	/**
	 * Create a task
	 * @param {String} name
	 * @param {Number} engagementId
	 * @param {Number} assignedUserId
	 * @param {Number} clientId
	 * @param {String} description
	 * @param {Date} deadline
	 * @param {String} notes
	 * @param {String} status
	 * @param {Boolean} notify
	 */
	async create({ name, engagementId, clientId, description, deadline, notes, status, notify, assignedUsers }) {
		let { authToken } = UserService.getUserData();

		try {
			await Kichiri.task.create(
				{
					name,
					engagementId,
					clientId,
					description,
					deadline,
					notes,
					status,
					notify,
					assignedUsers
				},
				{},
				authToken
			);
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Fetches a list of tasks
	 * @param {Number} assignedUserId
	 * @param {Number} engagementId
	 * @param {Number} clientId
	 * @param {String} filter
	 * @param {String} sortOrder
	 * @param {String} sortField
	 * @param {String} searchTerm
	 * @returns {Array}
	 */
	async list({
		searchTerm,
		sortOrder,
		sortField,
		limit = null,
		offset = 0,
		clientId,
		engagementId,
		assignedUsers,
		assignedStatuses,
		assignedClients,
		assignedClientGroups,
		dueDate
	}) {
		let { authToken } = UserService.getUserData();

		try {
			if (assignedUsers) {
				assignedUsers = JSON.stringify(assignedUsers);
			}

			let response = await Kichiri.task.list(
				{},
				{
					searchTerm,
					sortOrder,
					sortField,
					limit,
					offset,

					clientId,
					engagementId,
					assignedUsers,
					assignedStatuses,
					assignedClients,
					assignedClientGroups,
					dueDate
				},
				authToken
			);

			return response.data;
		} catch (error) {
			console.log(error);
		}

		return [];
	},

	async fetchTasksStatuses({ search }) {
		try {
			let statuses = Object.values(STATUSES);

			let filteredStatuses = statuses.filter(status => {
				let value = status.name.toLowerCase();

				return value.indexOf(search) !== -1;
			});

			return filteredStatuses;
		} catch (error) {
			console.log(error);
		}
	},

	/**
	 * Update tasks (one or more)
	 * @param {Array} taskIds - list of task id's to delete
	 * @return {String} response message
	 */
	async bulkUpdate({ taskIds, action }) {
		let { authToken } = UserService.getUserData();

		try {
			// call endpoint to update the tasks
			let response = await Kichiri.task.bulk_update({ taskIds, action }, {}, authToken);

			// return with response
			return response;
		} catch (error) {
			console.log(error);
		}
	}
};

export default TaskService;
