import React from "react";
import WComponent from "../WComponent";
import clsx from "clsx";
import "./budget-input.css";
class BudgetInput extends WComponent {
	constructor(props) {
		super(props);
	}

	render() {
		const { name, value, containerClass, className, onChange } = this.props;

		return (
			<div className={clsx("input-field", containerClass)}>
				<label className="input-field__title" htmlFor="budget">
					Enter Budget in Time (e.g., 10h 30m, 1h):
				</label>
				<input
					className={clsx("input-field__input", className, "ft-budget-time-input")}
					type="text"
					id="budget"
					name={name}
					value={value}
					onChange={onChange}
					placeholder="e.g., 10h 30m"
				/>
			</div>
		);
	}
}

export default BudgetInput;
