// External Libraries
import React from "react";
import Modal from "react-responsive-modal";
import * as Icon from "react-feather";

// Services
import ClientService from "../../../services/ClientService";
import ContactService from "../../../services/ContactService";
import ClientPortalService from "../../../services/ClientPortalService";
import UtilityService from "../../../services/UtilityService";

// Common Components
import Checkbox from "../../common/checkbox/Checkbox";
import WComponent from "../../common/WComponent";
import DateSelector from "../../common/date_selector/DateSelector";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import Button from "../../common/button/Button";
import DateInput from "../../common/date_input/DateInput";

// Components
import EmailSentModal from "./modals/email-sent/EmailSentModal";

// Styling
import "./contact-modal.css";

const { alert } = UtilityService;

class ContactsModal extends WComponent {
	constructor(props) {
		super(props);

		let { contactId, clientId } = this.props;

		this.state = {
			contactId,
			clientId,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			dob: new Date(),
			sin: "",
			address: "",
			primary: false,
			loading: false,
			hasAccount: false,

			searchMode: true,
			show: false,
			searchTerm: "",
			results: [],

			showDeleteModal: false,

			emailSent: false
		};
	}

	componentDidUpdate = prevProps => {
		let { show, contactId } = this.props;

		if (prevProps.contactId !== contactId || (prevProps.show === false && show === true)) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { contactId, clientId, show } = this.props;

		await this.update({
			loading: true
		});

		let searchMode = contactId === "new";

		await this.update({
			contactId,
			clientId,
			show,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			dob: new Date(),
			sin: "",
			address: "",
			searchMode
		});

		if (contactId !== "new") {
			await this.fetchContact();
		}

		await this.update({
			loading: false
		});
	};

	handleChange = e => {
		let target = e.target;
		let value = target.type === "checkbox" ? target.checked : target.value;

		this.update({
			[target.name]: value
		});
	};

	handleSinChange = e => {
		e.target.value = e.target.value.substring(0, 9);

		this.update({
			[e.target.name]: e.target.value
		});
	};

	isValid = () => {
		let { clientId, firstName, lastName, dob } = this.state;
		return clientId !== null && firstName.length > 0 && lastName.length > 0 && dob !== null;
	};

	clearModal = () => {
		let { contactId, clientId } = this.props;

		this.update({
			contactId,
			clientId,
			firstName: "",
			lastName: "",
			email: "",
			phone: "",
			dob: new Date(),
			sin: "",
			address: "",
			primary: false,
			loading: false
		});
	};

	fetchContact = async () => {
		let { contactId, clientId } = this.state;

		try {
			let response = await ContactService.getContact({ contactId, clientId });

			let contact = response.data;

			await this.update({
				clientId,
				firstName: contact.first_name,
				lastName: contact.last_name,
				email: contact.email,
				phone: contact.phone,
				dob: contact.dob,
				sin: contact.sin,
				address: contact.address,
				primary: contact.is_primary,
				hasAccount: contact.hasAccount
			});
		} catch (error) {
			console.log(error);
		}
	};

	createContact = async () => {
		let { loading, dob, clientId, firstName, lastName, email, phone, sin, address, primary } = this.state;

		let created = false;

		if (!this.isValid()) {
			alert({ type: "error", text: "Missing fields!" });
			return created;
		}

		if (loading) {
			alert({ type: "info", text: "Updating..." });
			return;
		}

		await this.update({
			loading: true
		});

		if (dob === "0000-00-00") {
			dob = null;
		}

		try {
			await ContactService.createContact({ clientId, firstName, lastName, email, phone, dob, sin, address, isPrimary: primary });

			created = true;
		} catch (error) {
			console.log(error);

			alert({ type: "error", text: "Create error!" });
			created = false;
		}

		await this.update({
			loading: false
		});

		this.clearModal();

		return created;
	};

	updateContact = async () => {
		let { contactId, clientId, firstName, lastName, email, phone, dob, sin, address, primary } = this.state;

		let updated = false;

		alert({ type: "info", text: "Updating..." });

		await this.update({
			loading: true
		});

		try {
			await ContactService.updateContact({ contactId, clientId, firstName, lastName, email, phone, dob, sin, address, isPrimary: primary });
			updated = true;
			alert({ type: "success", text: "Updated Successfully!" });
		} catch (error) {
			console.log(error);
			alert({ type: "error", text: "Updated error ..." });
		}

		await this.update({
			loading: false
		});

		this.clearModal();

		return updated;
	};

	onContactsModalClose = async () => {
		let result = null;

		if (this.isNewContact()) {
			result = await this.createContact();
		} else {
			result = await this.updateContact();
		}

		if (result && this.props.onClose) {
			this.props.onClose();
		}
	};

	isNewContact = () => {
		let { contactId } = this.state;
		return contactId === "new";
	};

	onDateOfBirthSelect = date => {
		this.update({
			dob: date
		});
	};

	detectSinChange = e => {
		let { sin } = this.state;
		if (e.keyCode === 8) {
			e.preventDefault();
			// if the SIN number's length is greater than 0
			if (sin.length > 0) {
				this.update({
					sin: ClientService.concatSinMinus({ sin })
				});
			}
		}
	};

	onClose = () => {
		this.update({
			show: false
		});

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onSearch = async event => {
		let { value } = event.target;

		await this.update({
			searchTerm: value
		});

		let results = await ContactService.searchContacts({ searchTerm: value });
		await this.update({ results });
	};

	onContactSelect = async ({ contact }) => {
		let { clientId } = this.state;

		let success = await ContactService.assignContactToClient({ contactId: contact.id, clientId });

		if (!success) {
			alert({ type: "error", text: "An error occurred trying to assign a contact." });
			return;
		}

		this.onClose();
		alert({ type: "success", text: "Successfully assigned contact!" });
	};

	onCreateNew = () => {
		this.update({
			searchMode: false
		});
	};

	renderModalHeader = () => {
		let { searchMode, contactId, firstName, lastName } = this.state;

		if (searchMode) {
			return (
				<div className="c-m__hd">
					<div className="c-m__hd__title">Assign a contact ...</div>
				</div>
			);
		}

		if (contactId === "new") {
			return (
				<div className="c-m__hd">
					<div className="c-m__hd__title">New Contact</div>
				</div>
			);
		}

		if (contactId !== "new") {
			let { hasAccount } = this.state;

			return (
				<div className="c-m__hd">
					<span className="c-m__hd__title">
						{firstName} {lastName}
					</span>

					{!searchMode && hasAccount && !this.isNewContact() && <div>Account Created</div>}
				</div>
			);
		}
	};

	removeContact = async () => {
		let { clientId, contactId } = this.state;

		alert({ type: "info", text: "Removing ..." });

		await this.update({
			loading: true
		});

		try {
			await ContactService.removeContact({ clientId, contactId });
			alert({ type: "success", text: "Contact successfully removed!" });
		} catch (error) {
			console.log(error);
		}

		await this.update({
			loading: false
		});

		this.clearModal();

		if (this.props.onClose) {
			this.props.onClose();
		}
	};

	onCloseDeleteModal = ({ confirmed }) => {
		console.log("confirmed: ", confirmed);

		this.update({
			showDeleteModal: false
		});

		if (confirmed) {
			this.removeContact();
		}
	};

	onConfirmModalOpen = () => {
		this.update({
			showDeleteModal: true
		});
	};

	sendAccSetupEmail = async () => {
		let { contactId } = this.state;

		let response = await ClientPortalService.emailNewAccountSetup({ contactId });

		if (response) {
			this.update({
				emailSent: true
			});
		}
	};

	sendResetPassEmail = async () => {
		let { email } = this.state;

		let response = await ClientPortalService.sendResetPassEmail({ contactEmail: email });

		if (response) {
			this.update({
				emailSent: true
			});
		}
	};

	getEmailButtonText() {
		let { searchMode, hasAccount } = this.state;

		let { isNewContact } = this;

		if (!searchMode && !hasAccount && !isNewContact) {
			return "Send Account Setup Email";
		}
		return "Send Reset Password Email";
	}

	handleEmailButtonClick = () => {
		const { searchMode, hasAccount } = this.state;
		if (!searchMode && !hasAccount && !this.isNewContact()) {
			this.sendAccSetupEmail();
		} else {
			this.sendResetPassEmail();
		}
	};

	render() {
		let { firstName, lastName, searchMode, show, searchTerm, results, email, phone, sin, dob, address, primary, emailSent, hasAccount } = this.state;
		let fullName = `${firstName} ${lastName}`;
		let { showDeleteModal } = this.state;

		let {
			onClose,
			renderModalHeader,
			onSearch,
			onCreateNew,
			onContactSelect,
			handleChange,
			onDateOfBirthSelect,
			onCloseDeleteModal,
			onConfirmModalOpen,
			onContactsModalClose,
			handleSinChange,
			isNewContact,
			handleEmailButtonClick
		} = this;

		const emailBtnTxt = this.getEmailButtonText();

		if (!searchMode && emailSent) {
			return (
				<EmailSentModal
					show={show}
					onClose={() => {
						this.update({
							emailSent: false
						});
						this.onClose();
					}}
				/>
			);
		}

		return (
			<Modal classNames={{ modal: "c-m" }} open={show} onClose={onClose} center>
				<div style={{ width: "100%" }}>
					{renderModalHeader()}

					{searchMode && (
						<div className="c-m__body__c-search">
							<div className="c-m__body__c-search__input">
								<TextFieldInput
									title="Search for contacts to assign..."
									name="search"
									onChange={onSearch}
									value={searchTerm}
									autoComplete="off"
									placeholder="Search"
								/>
								<Button text="Create" onClick={onCreateNew} />
							</div>

							<div className="c-m__body__c-search__results">
								{results.map(result => {
									return (
										<div className="c-m__body__c-search__results__item" onClick={() => onContactSelect({ contact: result })}>
											<div className="c-m__body__c-search__results__item-name">
												{result.first_name} {result.last_name}
											</div>

											<div className="c-m__body__c-search__results__item-email">{result.email}</div>
											<div className="c-m__body__c-search__results__item-phone">{result.phone}</div>
											<div className="c-m__body__c-search__results__item-sin">{result.sin}</div>
										</div>
									);
								})}
							</div>
						</div>
					)}

					{!searchMode && (
						<div className="c-m__body">
							<div className="c-m__body__inputs">
								<TextFieldInput title="First Name" name="firstName" onChange={handleChange} value={firstName} autoComplete="off" />
								<TextFieldInput title="Last Name" name="lastName" onChange={handleChange} value={lastName} autoComplete="off" />
								<TextFieldInput title="Email" name="email" onChange={handleChange} value={email} autoComplete="off" />
								<TextFieldInput title="Phone" name="phone" onChange={handleChange} value={phone} autoComplete="off" />
							</div>

							<div className="c-m__body__inputs">
								<TextFieldInput title="SIN" name="sin" onChange={handleSinChange} value={sin ? sin : ""} autoComplete="off" />

								<DateSelector
									className="contact-modal-datepicker"
									title="Date of Birth"
									selectedDate={dob}
									onDateSelect={onDateOfBirthSelect}
								/>

								<TextFieldInput title="Address" name="address" onChange={handleChange} value={address} autoComplete="off" />

								<Checkbox
									containerClass="c-m__body__inputs__is-primary"
									title="Primary Contact"
									name="primary"
									checked={primary}
									onChange={handleChange}
									autoComplete="off"
								/>
							</div>
						</div>
					)}
				</div>
				<div className="c-m__footer">
					{!searchMode && !isNewContact() && <Button view="secondary" icon={<Icon.Trash2 size={18} />} onClick={onConfirmModalOpen} />}
					{!searchMode && !isNewContact() && (
						<Button view="secondary" text={emailBtnTxt} icon={<Icon.Mail size={18} />} onClick={handleEmailButtonClick} />
					)}
					{!searchMode && <Button text={isNewContact() ? "Create" : "Update"} onClick={onContactsModalClose} />}
				</div>

				<Modal classNames={{ modal: "c-m__remove-contact" }} open={showDeleteModal} onClose={() => onCloseDeleteModal({ confirmed: true })} center>
					<div className="c-m__remove-contact__b__t">Remove Contact?</div>
					<div className="c-m__remove-contact__b__subt">{`Are you sure you want to remove ${fullName} as a contact?`}</div>
					<div className="c-m__remove-contact__b__footer">
						<Button text="Yes" onClick={() => onCloseDeleteModal({ confirmed: true })} />
						<Button text="No" onClick={() => onCloseDeleteModal({ confirmed: false })} />
					</div>
				</Modal>
			</Modal>
		);
	}
}

export default ContactsModal;
