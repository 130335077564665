import React from "react";
import * as Icon from "react-feather";
import _ from "lodash"; // Import lodash for deep comparison (if you prefer using lodash)

import WComponent from "../WComponent";
import SearchableDropdown from "../searchable_dropdown/SearchableDropdown";
import RolePicker from "../role_picker/RolePicker";
import UtilityService from "../../../services/UtilityService";
import Checkbox from "../checkbox/Checkbox";
import clsx from "clsx";

import "./user-assigner.css";

class UserAssigner extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			selectedUsers: JSON.parse(JSON.stringify(props.users))
		};
	}

	componentDidUpdate(prevProps) {
		let { users } = this.props;

		// Perform deep comparison using JSON.stringify() or lodash's isEqual()
		if (!_.isEqual(prevProps.users, users)) {
			let selectedUsers = JSON.parse(JSON.stringify(users)); // Deep clone if necessary

			this.update({
				selectedUsers
			});
		}
	}

	async componentDidMount() {}

	onSelectUser = async user => {
		let { selectedUsers } = this.state;

		let selectedUserRoleId = UtilityService.isNullish(user.defaultRole) ? 0 : user.defaultRole.id;
		let selectedUserRoleName = UtilityService.isNullish(user.defaultRole) ? "Staff" : user.defaultRole.name;

		selectedUsers.push({
			user_id: user.id,
			role_id: selectedUserRoleId,

			// render part
			userName: user.name,
			roleName: selectedUserRoleName,
			should_notify: false
		});

		await this.update({ selectedUsers });

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	onRemoveMember = async index => {
		let { selectedUsers } = this.state;

		selectedUsers.splice(index, 1);
		let newSelectedUsers = selectedUsers.slice();

		await this.update({
			selectedUsers: newSelectedUsers
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	onSelectRole = async (userIndex, role) => {
		let { selectedUsers } = this.state;

		selectedUsers = selectedUsers.slice();

		selectedUsers[userIndex].role_id = role.id;
		selectedUsers[userIndex].roleName = role.name;

		await this.update({
			selectedUsers
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	onNotificationChange = async ({ event, user, index }) => {
		let { selectedUsers } = this.state;

		selectedUsers = selectedUsers.slice();
		selectedUsers[index].should_notify = event.target.checked;

		await this.update({
			selectedUsers
		});

		if (this.props.onChange) {
			this.props.onChange({ selectedUsers });
		}
	};

	render() {
		let { selectedUsers } = this.state;
		let { hideRolePicker, showNotificationToggle, containerClass } = this.props;
		let { onSelectUser, onRemoveMember, onSelectRole, onNotificationChange } = this;

		return (
			<div className={clsx("user-assigner", containerClass)}>
				<SearchableDropdown title="Add a member ..." className="ft-user-dd" onSelect={onSelectUser} type={"users"} clearOnSelect />

				{selectedUsers.length > 0 ? (
					<div className="user-assigner__list">
						<div className="user-assigner__title">Current Users:</div>

						<div className="user-assigner__list__cards">
							{selectedUsers.map((user, index) => {
								return user.user_id ? (
									<div key={index} className="user-assigner__list__item">
										<div className="user-assigner__list__item__content">
											{!hideRolePicker && <RolePicker roleId={user.roleName} onSelect={role => onSelectRole(index, role)} />}

											<div className="user-assigner__list__item__content__divider" />
											<span className="user-assigner__list__item__content__user-name">{user.userName}</span>

											{showNotificationToggle && (
												<Checkbox
													style={{ marginTop: 0 }}
													isSmall
													title="Notify"
													name="notify"
													checked={user.should_notify}
													onChange={event => onNotificationChange({ event, user, index })}
												/>
											)}
										</div>

										<Icon.XCircle
											className="user-assigner__list__item__icon"
											size={28}
											color="#FF6B6B"
											onClick={() => {
												onRemoveMember(index);
											}}
										/>
									</div>
								) : null;
							})}
						</div>
					</div>
				) : (
					<div className="user-assigner__no-users">No users added.</div>
				)}
			</div>
		);
	}
}

export default UserAssigner;
