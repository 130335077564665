import React from "react";
import clsx from "clsx";
import "./tab.css";

const Tab = ({ id, className, icon, text, selected, onSelect }) => {
	let isSelected = id === selected;
	return (
		<div
			key={id}
			className={clsx("tab", className, {
				"tab--active": isSelected,
				"tab--icon-n-text": icon && text
			})}
			onClick={() => onSelect({ id, text })}
			data-tab-id={id}
		>
			{icon}
			{text}
		</div>
	);
};

export default Tab;
