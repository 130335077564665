import React from "react";
import moment from "moment";
import * as Icon from "react-feather";
import { ExternalLink as IconExternalLink } from "lucide-react";
import { Link } from "react-router-dom";

import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";

import UtilityService from "../../../services/UtilityService";

import { DeleteModal } from "./modals/DeleteModal";
import { UpdateModal } from "./modals/UpdateModal";

import RRuleEditor from "../../common/RRule/RRuleEditor";
import WComponent from "../../common/WComponent";
import Tasks from "../../tasks/Tasks";
import Notes from "../../notes/Notes";
import Events from "../../events/Events";
import NextEngagementModal from "../next_eng_modal/NextEngagementModal";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";
import SearchableDropdown from "../../common/searchable_dropdown/SearchableDropdown";
import UserAssigner from "../../common/user_assigner/UserAssigner";
import UserConstants from "../../../constants/Users";
import EngagementTemplateModal from "../EngagementTemplates/eng_template__modal/EngagementTemplateModal";
import DateSelector from "../../common/date_selector/DateSelector";
import Button from "../../common/button/Button";
import Tabs from "../../common/tabs/Tabs";
import Tab from "../../common/tabs/tab/Tab";
import Checkbox from "../../common/checkbox/Checkbox";
import EngagementTaskList from "../../common/engagement_tasks/EngagamentTasks";
import Budgets from "../../budgets/Budgets";

import "react-datepicker/dist/react-datepicker.css";
import "./engagement.css";

const { alert } = UtilityService;

class Engagement extends WComponent {
	constructor() {
		super();
		this.state = {
			loading: true,
			engagement: null,
			search: "",
			errors: {},
			type: "t1",

			engagementId: 0,
			teamId: 0,
			clientId: 0,
			clientType: "",
			name: "",
			status: "",
			dateIn: new Date(),
			dueDate: new Date(),
			fiscalYearEnd: new Date(),
			notes: "",
			frequency: "",

			showDeleteModal: false,
			showNextModal: false,

			showUpdateModal: false,

			flow: {},
			flowCode: "t1",
			flowStates: [],
			view: "details",

			selectedTeamName: "",
			selectedClientName: "",
			selectedFlowStateName: "",
			selectedFlowStateId: 0,

			assignedUsers: [],

			rrule: "",
			engagementSnapshot: "",
			showCreateTemplateModal: false,
			taxEstimate: false
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		if (prevProps.match.params.engagementId !== this.props.match.params.engagementId) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { engagementId } = this.props.match.params;

		await this.update({ loading: true, engagementId });

		await this.fetchEngagement();
		await this.fetchTeam();

		await this.update({ loading: false });
	};

	fetchTeam = async () => {
		let { teamId } = this.state;

		let team = await UserService.fetchTeam({ teamId });

		if (!team) {
			return;
		}

		await this.update({ selectedTeamName: team.name });
	};

	fetchEngagement = async () => {
		let { engagementId } = this.state;

		try {
			let { data: engagement } = await EngagementService.get({ engagementId });

			let assignedUsers = engagement.engagement_users.map(eu => {
				return {
					user_id: eu.user_id,
					role_id: eu.role_id,

					userName: `${eu.User.first_name} ${eu.User.last_name}`,
					roleName: `${eu.Role.name}`
				};
			});

			// Attach existing user title
			let tasks = engagement.tasks.map(task => {
				let roleName = null;

				if (task.task_users && task.task_users.length > 0) {
					roleName = task.task_users[0].Role.name;
				}

				return {
					...task,
					assignedUser: {
						name: roleName || UserConstants.roleType.NO_ROLE.value
					}
				};
			});

			await this.update({
				teamId: engagement.team_id || 0,
				clientId: engagement.Client ? engagement.Client.id : 0,
				clientType: engagement.Client ? engagement.Client.type : "",
				engagementId: engagement.id,
				flowId: engagement.flow_id,
				flowStateId: engagement.flow_state_id,
				flowCode: engagement.flow.code,

				name: engagement.name,
				status: engagement.status,
				dueDate: moment(engagement.due_date).toDate(),
				dateIn: moment(engagement.date_in).toDate(),
				fiscalYearEnd: moment(engagement.fiscal_year_end).toDate(),
				notes: engagement.notes,
				frequency: engagement.frequency,

				selectedClientName: engagement.Client ? engagement.Client.name : "No Assigned Client",
				selectedFlowStateName: engagement.flow_state.name,
				selectedFlowStateId: engagement.flow_state.id,

				assignedUsers,

				recurringEngagement: engagement.RecurringEngagement,
				tasks,
				taxEstimate: engagement.tax_estimate
			});
		} catch (error) {
			console.log(error);
		}
	};

	updateEngagement = async () => {
		let { engagementId, teamId, clientId, flowStateId, name, dueDate, dateIn, fiscalYearEnd, notes, assignedUsers, loading, taxEstimate } = this.state;

		if (!this.isValid()) {
			alert({ type: "error", text: "Missing fields!" });
			return;
		}

		if (loading) {
			alert({ type: "info", text: "Updating..." });

			return;
		}

		await this.update({
			loading: true
		});

		try {
			dateIn = moment(dateIn).format("YYYY-MM-DD");
			dueDate = moment(dueDate).format("YYYY-MM-DD");
			fiscalYearEnd = moment(fiscalYearEnd).format("YYYY-MM-DD");

			let {
				data: { next }
			} = await EngagementService.update({
				engagementId,
				teamId,
				clientId,
				flowStateId,
				name,
				dueDate,
				dateIn,
				fiscalYearEnd,
				notes,
				assignedUsers,
				taxEstimate
			});

			alert({ type: "success", text: "Updated Successfully!" });

			if (next) {
				await this.update({
					showNextModal: true
				});
			}
		} catch (error) {
			alert({ type: "error", text: "Update Error!" });
		}

		await this.update({
			loading: false
		});
	};

	onDeleteEngagement = async () => {
		let { engagementId } = this.state;

		this.update({
			loading: true
		});

		let success = await EngagementService.deleteEngagement({ engagementId });

		if (success) {
			alert({ type: "success", text: "Deleted Successfully!" });
		} else {
			alert({ type: "error", text: "Delete Error!" });
		}

		this.update({
			loading: false
		});
	};

	onFlowStateSelect = flowState => {
		this.update({
			flowStateId: flowState.id,
			selectedFlowStateName: flowState.name
		});
	};

	onClientSelect = item => {
		this.update({
			clientId: item.id,
			clientType: item.type
		});
	};

	onChange = event => {
		let { name, value, type, checked } = event.target;

		if (type === "checkbox") {
			value = checked;
		}

		this.update({
			[name]: value
		});
	};

	onBack = () => {
		this.props.history.goBack();
	};

	onDateInSelect = date => {
		this.update({
			dateIn: date
		});
	};

	onDueDateSelect = date => {
		this.update({
			dueDate: date
		});
	};

	onFiscalYearEndSelect = date => {
		this.update({
			fiscalYearEnd: date
		});
	};

	isDisabled() {
		return !this.isValid() || this.state.loading;
	}

	isValid = () => {
		let { name, fiscalYearEnd } = this.state;
		return name && name.length > 0 && fiscalYearEnd !== null;
	};

	onConfirmModalOpen = () => {
		this.update({
			showDeleteModal: true
		});
	};

	onUpdateModalOpen = () => {
		this.update({
			showUpdateModal: true
		});
	};

	onUpdateModalClose = confirmed => {
		this.update({
			showUpdateModal: false
		});

		if (confirmed) {
			this.updateEngagement();
			this.updateRecurringEngagement();
		}
	};

	updateRecurringEngagement = async () => {
		const { recurringEngagement, teamId, assignedUsers } = this.state;

		if (recurringEngagement) {
			let recurringEngagementSnapshot = JSON.parse(recurringEngagement.engagement_snapshot);
			if (teamId) {
				recurringEngagementSnapshot.teamId = teamId;
			}

			if (assignedUsers) {
				recurringEngagementSnapshot.assignedUsers = assignedUsers;
			}

			await EngagementService.updateRecurringEngagement({
				recurringEngagementId: recurringEngagement.id,
				rrule: recurringEngagement.rrule,
				engagementSnapshot: recurringEngagementSnapshot
			});
		}
	};

	onCloseDeleteModal = confirmed => {
		let { type } = this.state;
		let { history } = this.props;

		this.update({
			showDeleteModal: false
		});

		if (confirmed) {
			this.onDeleteEngagement();
			history.push(`/engagements`);
		}
	};

	onCloseUpdateModal = () => {
		this.update({
			showUpdateModal: false
		});
	};

	switchView = view => {
		this.update({
			view: view.id
		});
	};

	onSelectTeam = async team => {
		await this.update({
			teamId: team.id,
			selectedTeamName: team.name
		});

		// fetch users associated with the team
		await this.onFetchUsersByTeam({ teamId: team.id });
	};

	onFetchUsersByTeam = async ({ teamId }) => {
		let team = await UserService.fetchTeam({ teamId });

		// update the assigend users of the selected new team
		await this.update({
			assignedUsers: team.users
		});
	};

	onAssignedUsersChanged = async ({ selectedUsers }) => {
		await this.update({
			assignedUsers: selectedUsers
		});
	};

	onRRuleChange = async ({ rrule }) => {
		await this.update({ rrule });
	};

	onUpdateRecurringEngagementClicked = async () => {
		let { rrule, recurringEngagement, loading, engagementSnapshot } = this.state;

		if (!rrule && recurringEngagement.rrule) {
			rrule = recurringEngagement.rrule;
		}
		if (loading) {
			return;
		}

		await this.update({ loading: true });

		let toastId = alert({ type: "info", text: "Updating ...", timeout: 20000 });

		let success = await EngagementService.updateRecurringEngagement({
			recurringEngagementId: recurringEngagement.id,
			rrule,
			engagementSnapshot
		});

		await this.update({ loading: false });

		alert({ type: "dismiss", id: toastId });

		if (!success) {
			alert({ type: "error", text: "Unable to update Recurring Settings..." });
		} else {
			alert({ type: "success", text: "Recurring Engagement updated!" });
		}
	};

	updateEngagementSnapshot = ({ taskSnapShot, snapshot }) => {
		let updatedSnapshot = { ...snapshot, tasks: taskSnapShot };
		this.update({ engagementSnapshot: updatedSnapshot });
	};

	renderRepeats() {
		const { updateEngagementSnapshot } = this;
		let { recurringEngagement } = this.state;
		let rrule = recurringEngagement.rrule;
		let snapshot = JSON.parse(recurringEngagement.engagement_snapshot);
		let snapshotTasks = snapshot.tasks;

		return (
			<div className="engagement__repeats">
				<div className="re-parent">
					<div className="re-parent__editor">
						<RRuleEditor onChange={this.onRRuleChange} rrule={rrule} />
					</div>
					<div className="re-parent__board">
						<EngagementTaskList
							tasks={snapshotTasks}
							updateEngagementSnapshot={taskSnapShot => updateEngagementSnapshot({ taskSnapShot, snapshot })}
						></EngagementTaskList>
					</div>
				</div>
				<div className="engagement__repeats__footer">
					<Button onClick={this.onUpdateRecurringEngagementClicked} text="Update" className={"ft-update-recurrence"} />
				</div>
			</div>
		);
	}

	renderDetails() {
		const {
			name,
			selectedClientName,
			clientType,
			clientId,
			selectedTeamName,
			flow,
			flowCode,
			selectedFlowStateName,
			dateIn,
			dueDate,
			fiscalYearEnd,
			assignedUsers,
			taxEstimate
		} = this.state;

		return (
			<div className="engagement">
				<div className="engagement__body">
					<div className="engagement__body__left">
						<TextFieldInput title="Name" name="name" onChange={this.onChange} autoComplete="off" value={name} className="ft-eng-det-name" />

						<SearchableDropdown
							containerClass="engagement__body__input"
							title="Client"
							onSelect={this.onClientSelect}
							type={"clients"}
							selectedName={selectedClientName}
							className="ft-eng-det-client"
						/>

						<div className="engagement__body__left__view-client">
							<Link className="engagement__body__left__view-client__link" to={`/clients/${clientType}/${clientId}`} target="_blank">
								View Client
								<IconExternalLink className="engagement__body__left__view-client__link__icon" size={13} />
							</Link>
						</div>

						<SearchableDropdown
							containerClass="engagement__body__input"
							title="Team"
							onSelect={this.onSelectTeam}
							type={"teams"}
							selectedName={selectedTeamName}
							showAll
							className="ft-eng-form-team"
						/>

						<SearchableDropdown
							containerClass="engagement__body__input"
							title="Status"
							onSelect={this.onFlowStateSelect}
							flowCode={flowCode}
							firstFocus
							showAll
							type={"flowStates"}
							selectedName={selectedFlowStateName}
							className="ft-eng-form-status"
						/>

						<DateSelector
							containerClass="engagement__body__input"
							title="Date In"
							selectedDate={dateIn}
							timeIntervals={30}
							onDateSelect={this.onDateInSelect}
							dateFormat="MMMM d, yyyy"
							className="ft-eng-det-datein"
						/>

						<DateSelector
							containerClass="engagement__body__input"
							title="Due Date"
							selectedDate={dueDate}
							timeIntervals={30}
							onDateSelect={this.onDueDateSelect}
							dateFormat="MMMM d, yyyy"
							className="ft-eng-det-duedate"
						/>

						<DateSelector
							containerClass="engagement__body__input"
							title="Fiscal Year End"
							selectedDate={fiscalYearEnd}
							timeIntervals={15}
							onDateSelect={this.onFiscalYearEndSelect}
							dateFormat="MMMM d, yyyy"
							className="ft-eng-det-fiscyearend"
						/>

						<Checkbox
							containerClass="engagement__body__input"
							title="Tax Estimate"
							name="taxEstimate"
							checked={taxEstimate}
							onChange={this.onChange}
						/>
					</div>
					<div className="engagement__body__right">
						<UserAssigner onChange={this.onAssignedUsersChanged} users={assignedUsers} />
					</div>
				</div>
				<div className="btn-group">
					<Button
						view="secondary"
						onClick={() => this.onConfirmModalOpen()}
						icon={<Icon.Trash2 size={22} />}
						className={"ft-eng-det-delete-template-btn"}
					/>
					<Button
						view="secondary"
						text="Create Template"
						onClick={() => {
							this.update({
								showCreateTemplateModal: true
							});
						}}
						className="ft-eng-det-create-template"
					/>
					<Button onClick={this.onUpdateModalOpen} text="Update" disabled={this.isDisabled()} />
				</div>
			</div>
		);
	}

	render() {
		let {
			// Toggles
			view,
			showDeleteModal,
			showUpdateModal,
			showNextModal,

			// Metadata
			flow,
			engagementId,
			clientId,

			name,

			recurringEngagement,
			showCreateTemplateModal
		} = this.state;

		let { switchView } = this;

		return (
			<div>
				<div className="eng">
					<div className="eng__hd">
						<div className="eng__hd__title">
							<div className="eng__hd__title__name">
								<div className="eng__hd__title__name__back" onClick={this.onBack}>
									<Icon.ArrowLeftCircle size={32} />
								</div>
								{name}
							</div>
						</div>
					</div>

					<Tabs onSelect={switchView} selected={view}>
						<Tab id={"details"} icon={<Icon.User size={14} strokeWidth={1.5} />} text="Details" />
						<Tab id={"notes"} icon={<Icon.FileText size={14} strokeWidth={1.5} />} text="Notes" />
						<Tab id={"tasks"} icon={<Icon.List size={14} strokeWidth={1.5} />} text="Tasks" />
						<Tab id={"events"} icon={<Icon.Bell size={14} strokeWidth={1.5} />} text="Events" />
						{recurringEngagement && <Tab id={"repeats"} icon={<Icon.Bell size={14} strokeWidth={1.5} />} text="Repeat" />}
						<Tab id={"budgets"} icon={<Icon.Clock size={14} strokeWidth={1.5} />} text="Budgets" />
					</Tabs>

					{view === "details" && this.renderDetails()}

					{view === "repeats" && this.renderRepeats()}
				</div>

				<DeleteModal show={showDeleteModal} onClose={this.onCloseDeleteModal} />

				<UpdateModal show={showUpdateModal} onClose={this.onUpdateModalClose} />

				<EngagementTemplateModal
					show={showCreateTemplateModal}
					onCreate={() => {
						alert({ type: "info", text: "Created Template" });

						this.update({ showCreateTemplateModal: false });
					}}
					onClose={() => {
						this.update({ showCreateTemplateModal: false });
					}}
					engagementTemplateId={"new"}
					name=""
					selectedFlow={{
						flowType: {
							id: this.state.flow.id,
							name: this.state.flow.name,
							code: this.state.flowCode
						},
						flowState: {
							id: this.state.selectedFlowStateId,
							name: this.state.selectedFlowStateName
						}
					}}
					selectedTeam={{
						id: this.state.teamId,
						name: this.state.selectedTeamName
					}}
					tasks={this.state.tasks}
				/>

				{view === "notes" && <Notes engagementId={engagementId} clientId={clientId} {...this.props} />}
				{view === "tasks" && <Tasks title="Tasks" engagementId={engagementId} showViews={false} showFilters={false} {...this.props} />}
				{view === "events" && <Events raw={true} title="Events" engagementId={engagementId} {...this.props} />}
				{view === "budgets" && <Budgets raw={true} title="Budgets" engagementId={engagementId} {...this.props} />}
				{engagementId && <NextEngagementModal show={showNextModal} engagementId={engagementId} />}
			</div>
		);
	}
}

export default Engagement;
