import React from "react";
import UserService from "../../services/UserService";
import TextFieldInput from "../common/text_field_input/TextFieldInput";
import UtilityService from "../../services/UtilityService";

import StaffService from "../../services/StaffService";
import WComponent from "../common/WComponent";
import SearchableDropdown from "../common/searchable_dropdown/SearchableDropdown";
import Button from "../common/button/Button";
import PageHeading from "../common/page_heading/PageHeading";

import "./settings.css";
import "./staff-settings.css";

const { alert } = UtilityService;

class StaffSettings extends WComponent {
	constructor() {
		super();

		this.state = {
			loading: true,

			firstName: "",
			lastName: "",
			title: "",
			email: "",
			phone: "",
			password: "",
			confirmPassword: "",
			status: "",
			defaultRoleId: 0,
			defaultRoleName: ""
		};
	}

	componentDidMount = () => {
		this.resetComponent();
	};

	componentDidUpdate = prevProps => {
		let { userId } = this.props.match.params;

		if (prevProps.match.params.userId !== userId) {
			this.resetComponent();
		}
	};

	resetComponent = async () => {
		let { userId } = this.props.match.params;

		await this.update({
			userId: userId
		});

		await this.update({
			loading: true
		});

		if (userId === "new") {
			await this.update({
				loading: false
			});
			return;
		}

		await this.fetchUser();
	};

	fetchUser = async () => {
		let { userId } = this.state;

		let user = await UserService.fetchUser({ userId });

		await this.update({
			loading: false
		});

		if (!user) {
			alert({ type: "error", text: "User does not exist." });
			this.props.history.push("/settings/company");
			return;
		}

		await this.update({
			firstName: user.first_name,
			lastName: user.last_name,
			title: user.title,
			email: user.email,
			phone: user.phone,
			status: user.status,
			defaultRoleId: UtilityService.isNullish(user.defaultRole) ? undefined : user.defaultRole.id,
			defaultRoleName: UtilityService.isNullish(user.defaultRole) ? undefined : user.defaultRole.name
		});
	};

	updateUser = async () => {
		let { userId, firstName, lastName, title, email, phone, status, password, defaultRoleId } = this.state;

		if (!this.isValid()) {
			alert({ type: "error", text: "Missing fields!" });
			return;
		}

		if (this.state.loading) {
			alert({ type: "info", text: "Updating..." });
			return;
		}

		try {
			await StaffService.update({
				userId: userId,
				firstName: firstName,
				lastName: lastName,
				password: password,
				title: title,
				email: email,
				phone: phone,
				status: status,
				defaultRoleId: defaultRoleId
			});

			alert({ type: "success", text: "Updated Successfully!" });
		} catch (error) {
			alert({ type: "error", text: "Update Error!" });
			console.log(error);
		}

		await this.update({
			loading: false
		});
	};

	createUser = async () => {
		let { history } = this.props;
		let { firstName, lastName, title, email, phone, password, defaultRoleId } = this.state;

		if (!this.isValid()) {
			// Call alert saying there are missing fields
			alert({ type: "error", text: "Missing fields!" });
			return;
		}

		if (this.state.loading) {
			// Call alert saying the client is being updated
			alert({ type: "info", text: "Updating..." });
			return;
		}

		this.update({
			loading: true
		});

		try {
			let response = await StaffService.create({ firstName, lastName, title, email, phone, password, defaultRoleId });

			// Call alert saying that the client was updated successfully
			alert({ type: "success", text: "Created Successfully!" });
			history.push(`/settings/users/${response.data.id}`);
		} catch (error) {
			console.log(error);
			// Call alert saying there is some kind of error trying to update the client
			alert({ type: "error", text: "Update Error!" });
		}

		this.update({
			loading: false
		});
	};

	archiveUser = async () => {
		let { loading, status, userId } = this.state;

		if (!this.isValid()) {
			alert({ type: "error", text: "Missing fields!" });
			return;
		}

		if (loading) {
			alert({ type: "info", text: "Updating..." });
			return;
		}

		let newStatus = status === "active" ? "inactive" : "active";

		try {
			await StaffService.update({ userId, status: newStatus });

			alert({ type: "success", text: "Updated Successfully!" });

			await this.update({
				status: newStatus
			});
		} catch (error) {
			alert({ type: "error", text: "Update Error!" });
			console.log(error);
		}

		this.update({
			loading: false
		});
	};

	onChange = e => {
		let { name, value } = e.target;

		this.update({
			[name]: value
		});
	};

	onTitleSelect = title => {
		this.update({
			title: title.key
		});
	};

	onSelectDefaultRole = role => {
		this.update({
			defaultRoleId: role.id,
			defaultRoleName: role.name
		});
	};

	onBack = () => {
		let { history } = this.props;
		history.goBack();
	};

	isNewUser = () => {
		let { userId } = this.state;
		return userId === "new";
	};

	isDisabled = () => {
		let { loading } = this.state;
		return !this.isValid() || loading;
	};

	isPasswordCorrectLength = () => {
		let { password } = this.state;

		return password.length >= 8;
	};

	isPasswordMatching = () => {
		let { password, confirmPassword } = this.state;
		return password === confirmPassword && password.length >= 8;
	};

	isValid = () => {
		let { firstName, lastName, email, password, confirmPassword } = this.state;

		let isPasswordValid =
			this.isNewUser() || password.length > 0 || confirmPassword.length > 0 ? password === confirmPassword && password.length >= 8 : true;

		if (!firstName || !lastName || !email) {
			return;
		}

		return firstName.length > 0 && lastName.length > 0 && email.length > 0 && isPasswordValid;
	};

	render() {
		let { firstName, lastName, title, email, phone, password, confirmPassword, status, defaultRoleName } = this.state;

		return (
			<div className="staff-settings">
				<PageHeading section="Settings" title={"Edit Profile"} />

				<div className="staff-settings__form">
					<div>
						<TextFieldInput
							containerClass="staff-settings__form__input"
							title="First Name"
							name="firstName"
							value={firstName || ""}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-firstname"
						/>

						<TextFieldInput
							containerClass="staff-settings__form__input"
							title="Last Name"
							name="lastName"
							value={lastName || ""}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-lastname"							
						/>

						<TextFieldInput
							containerClass="staff-settings__form__input"
							title="Title"
							name="title"
							value={title || ""}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-title"							
						/>

						<SearchableDropdown
							containerClass="staff-settings__form__input"
							title="Default Role"
							type={"roles"}
							selectedName={defaultRoleName || ""}
							onSelect={this.onSelectDefaultRole}
							autoComplete="off"
							className="ft-user-form-role"							
						/>

						{/*<div className="contact-section">
                                <div>
                                    <h5>Preferred Contact Medium</h5>
                                </div>

                                <div className="contact-buttons">
                                    <Link to="#" style={{ textDecoration: 'none'}}>
                                        <input type="submit" value="Email" id="email-button"/>
                                    </Link>
                                    <Link to="#" style={{ textDecoration: 'none' }}>
                                        <input type="submit" value="SMS" id="sms-button"/>
                                    </Link>
                                </div>
                            </div>*/}
					</div>
					<div className="staff-settings-container-2">
						<TextFieldInput
							containerClass="staff-settings__form__input"
							disabled={!UserService.isAdministrator()}
							title="Email"
							name="email"
							value={email || ""}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-email"							
						/>

						<TextFieldInput
							containerClass="staff-settings__form__input"
							title="Phone"
							name="phone"
							value={phone || ""}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-phone"							
						/>

						<TextFieldInput
							containerClass="staff-settings__form__input"
							title="Password"
							type="password"
							name="password"
							value={password}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-password"							
						/>

						<TextFieldInput
							containerClass="staff-settings__form__input"
							title="Confirm Password"
							type="password"
							name="confirmPassword"
							value={confirmPassword}
							onChange={this.onChange}
							autoComplete="off"
							className="ft-user-form-confirmpassword"							
						/>

						<div className="user-password">{this.isPasswordMatching() ? "Password Matches" : ""}</div>
					</div>
					<div className="staff-settings__form__footer">
						{!this.isNewUser() && <Button view="secondary" text={status === "active" ? "Archive" : "Unarchive"} onClick={this.archiveUser} className="ft-user-archivebtn"/>}
						<Button text={this.isNewUser() ? "Create" : "Update"} onClick={this.isNewUser() ? this.createUser : this.updateUser} className="ft-user-createupdatebtn"/>
					</div>
				</div>
			</div>
		);
	}
}

export default StaffSettings;
