import React from "react";
import { Link } from "react-router-dom";
import qs from "query-string";
import clsx from "clsx";

import * as Icon from "react-feather";
import { debounce } from "lodash";

import WComponent from "../../common/WComponent";
import TextFieldInput from "../../common/text_field_input/TextFieldInput";

import AuthService from "../../../services/AuthService";
import UtilityService from "../../../services/UtilityService";

import "./set-password.css";

const { alert } = UtilityService;

/**
 * @class SetPassword
 * @description Component for the set password page
 * @extends {WComponent<SetPassword.propTypes>}
 */
export default class SetPassword extends WComponent {
	constructor(props) {
		super(props);
		this.state = {
			token: "",
			email: "",
			password: "",
			confirmPassword: "",
			warningMessage: "",
			isAboutToRedirect: false,
			updatingPassword: false
		};
	}

	resetComponent = async () => {
		let params = qs.parseUrl(window.location.href);
		let { token, email } = params.query;

		await this.update({
			token: token,
			email: email || "",
			password: "",
			confirmPassword: "",
			warningMessage: "",
			isAboutToRedirect: false,
			isUpdatingPassword: false
		});
	};

	componentDidMount = async () => {
		const { resetComponent, validateResetPasswordToken } = this;
		const { history } = this.props;
		await resetComponent();

		try {
			await validateResetPasswordToken();
		} catch (error) {
			alert({ type: "error", text: "Token invalid, redirecting in 5 seconds ..." });
			setTimeout(() => {
				history.push("/reset-password");
			}, 5000);
		}
	};

	doPasswordsMatch = () => {
		const { password, confirmPassword } = this.state;
		if (!confirmPassword || !password) {
			return false;
		}

		return password === confirmPassword;
	};
	isSubmitSetPasswordDisabled = () => {
		const { email, isUpdatingPassword } = this.state;

		const fieldsNotFilled = !UtilityService.isTextInEmailFormat({ text: email }) || this.doPasswordsMatch() === false || isUpdatingPassword;

		return fieldsNotFilled;
	};

	validateResetPasswordToken = async () => {
		const { email, token } = this.state;

		try {
			return AuthService.validateResetToken({ email, token });
		} catch (error) {
			console.log(error);
			return;
		}
	};

	onUpdatePassword = async () => {
		const { password, confirmPassword, token, email } = this.state;
		const { history } = this.props;

		if (password !== confirmPassword) {
			alert({ type: "error", text: "Passwords do not match" });
			return;
		}

		await this.update({ isUpdatingPassword: true });

		const isPasswordUpdated = await AuthService.updatePassword({
			token,
			email,
			password,
			confirmPassword
		});

		if (isPasswordUpdated) {
			alert({ type: "success", text: "Password updated successfully, Redirected in 5 seconds ..." });
			setTimeout(() => {
				history.push("/login");
			}, 5000);
		}
	};

	onUpdatePasswordField = async e => {
		if (e.target.name === "password") {
			await this.update({ password: e.target.value });
		}
		if (e.target.name === "confirmPassword") {
			await this.update({ confirmPassword: e.target.value });
		}
	};

	render() {
		const { onUpdatePassword, isSubmitSetPasswordDisabled, onUpdatePasswordField } = this;
		const { email, password, confirmPassword, isUpdatingPassword } = this.state;

		const debouncedUpdatePassword = debounce(onUpdatePassword, 500, {
			leading: false,
			trailing: true
		});

		return (
			<div className="set-password">
				<div className="set-password__body">
					<div className="set-password__body__heading">
						<p className="set-password__body__heading__title">Set your password!</p>
						<p className="set-password__body__heading__subtitle">Please enter your details.</p>
					</div>

					<div className="set-password__body__form">
						<TextFieldInput
							name={"Email"}
							title={"Email"}
							autoComplete={"off"}
							type={"email"}
							view="auth"
							value={email}
							disabled={true}
							onChange={e => this.update({ email: e.target.value })}
							style={{ cursor: "not-allowed", backgroundColor: "#ededed" }}
						/>

						<TextFieldInput
							name={"password"}
							title={"Password"}
							autoComplete={"off"}
							type={"password"}
							view="auth"
							value={password}
							onChange={onUpdatePasswordField}
						/>

						<TextFieldInput
							name={"confirmPassword"}
							title={"Confirm Password"}
							autoComplete={"off"}
							type={"password"}
							view="auth"
							value={confirmPassword}
							onChange={onUpdatePasswordField}
						/>
						{this.doPasswordsMatch() ? (
							<div className="set-password__password__match">
								<div className="set-password__password__match__text">
									<Icon.CheckCircle size={16} color="green" />
									<p>Match</p>
								</div>
							</div>
						) : null}
						<div
							className={clsx("set-password__btn", { "set-password__btn--disabled": isSubmitSetPasswordDisabled() })}
							onClick={debouncedUpdatePassword}
						>
							{!isUpdatingPassword ? "Update Password" : "Updating Password ..."}
						</div>
					</div>
					<hr className="set-password__hr"></hr>
					<div className="set-password__secondary__action">
						<p className="set-password__secondary__action__message">Already have a Workflow account?</p>
						<Link className="set-password__secondary__action__link" to="/login">
							Sign in
						</Link>
					</div>
				</div>
			</div>
		);
	}
}
