// External Libraries
import React from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import moment from "moment";
import Board from "react-trello";
import { CopyMinus, ExternalLink as IconExternalLink } from "lucide-react";
import { Trello, ListChecks } from "lucide-react";
import clsx from "clsx";

// Constants
import WORKFLOW from "../../constants/Workflow";
import { DEADLINES, STATUSES } from "../../constants/Tasks";

// Services
import UserService from "../../services/UserService";
import EngagementService from "../../services/EngagementService";
import EngagementModal from "./eng_modal/EngagementModal.js";
import TaskService from "../../services/TaskService";
import UtilityService from "../../services/UtilityService";

// Components
import AutoGenerateModal from "./auto_generate/AutoGenerateModal";
import EngagementFilterModal from "./EngagmentFilterModal/EngagementFilterModal";
import ViewList from "./ViewList/ViewList";

// Common Components
import UserBubbles from "../common/UserBubbles/UserBubbles";
import WComponent from "../common/WComponent";
import TaskModal from "../tasks/task-modal/TaskModal.js";
import Checkbox from "../common/checkbox/Checkbox";
import Loader from "../common/loader/Loader";
import ScrollableContainer from "../common/scrollable_container/ScrollableContainer.js";

// Specialty Services
import FloatingContainerService from "../../services/FloatingContainerService.js";
import SearchInput from "../common/search_input/SearchInput.js";

import Button from "../common/button/Button.js";

import Tabs from "../common/tabs/Tabs.js";
import Tab from "../common/tabs/tab/Tab.js";
import PageHeading from "../common/page_heading/PageHeading.js";

// Styling
import "../../../node_modules/react-grid-layout/css/styles.css";
import "../../../node_modules/react-resizable/css/styles.css";
import "../common/dropdown/dropdown.css";
import "./engagements.css";
import "./engagements-kanban.css";

const KANBAN_STYLES = {
	deadline: "deadline",
	status: "status"
};

const { alert } = UtilityService;

class Engagements extends WComponent {
	constructor(props) {
		super(props);

		this.state = {
			engagementId: 0,
			engagements: [],
			search: "",

			title: props.title,
			dashboard: props.dashboard,
			refresh: true,
			showNotesEditor: false,
			engNotes: "",

			newEngagementId: null,
			showAutoGenerateModal: false,

			limit: 50,
			offset: 0,
			loadedAll: false,
			loading: true,

			sort: [{ field: WORKFLOW.orderQueries.fiscalYearEnd, order: "desc" }],

			showFilterModal: false,

			pageView: "list",
			kanbanStyle: KANBAN_STYLES.status,
			showFilters: false,
			// View Related State
			views: [],
			isViewChanged: false,
			showViewModal: false,
			selectedViewId: null,
			savingView: false,
			overwritingView: false,
			minimizeViews: false,

			// Filters Related State
			assignedUsers: [],
			assignedTeams: [],
			assignedClients: [],
			assignedClientGroups: [],
			selectedFlowStates: [],
			yearEnd: WORKFLOW.defaultDateFunction,
			dueDate: WORKFLOW.defaultDateFunction,
			dateIn: WORKFLOW.defaultDateFunction,
			lastActivityDate: null,
			columns: null,
			shouldShowInactive: false,
			defaultViewSelected: null,

			// Tasks an Engagement Related State
			showTaskModal: false,
			selectedTaskId: 0,
			showKanban: false,
			isAllEngagementsExpanded: true,
			showEngagementModal: false,
			totalEngagementCount: 0,
			totalTaskCount: 0
		};

		this.notesEditor = null;

		this.fetchEngagementsDebounce = _.debounce(this.fetchEngagements, 500, {
			leading: true,
			trailing: true
		});
	}

	componentDidMount = () => {
		this.resetComponent({});
	};

	componentDidUpdate = async prevProps => {
		let newProps = this.props;
		let oldProps = prevProps;

		if (oldProps.clientId !== newProps.clientId || (oldProps.showViews !== newProps.showViews) | (oldProps.showFilters !== newProps.showFilters)) {
			await this.resetComponent();
		}
	};

	overrideAssignedUsers = () => {
		let { defaultViewSelected } = this.state;

		let assignedUsers = [];

		// If a default view is selected and it is the "assignedToMe" static view
		let isAssignedToMe = defaultViewSelected && defaultViewSelected.id === "assignedToMe";

		if (!isAssignedToMe) {
			return assignedUsers;
		}

		let userData = UserService.getUserData();
		assignedUsers = [
			{
				user_id: userData.userId,
				userName: userData.firstName + " " + userData.lastName
			}
		];

		return assignedUsers;
	};

	resetComponent = async () => {
		let { clientId, showFilters, showViews } = this.props;

		let flowStates = await this.getInitialFlowStates();
		let assignedUsers = this.overrideAssignedUsers();

		let newState = {
			limit: 50,
			loading: true,

			assignedUsers,
			assignedClients: [],
			assignedClientGroups: [],
			selectedFlowStates: flowStates,
			assignedTeams: [],
			yearEnd: WORKFLOW.defaultDateFunction,
			dueDate: WORKFLOW.defaultDateFunction,
			dateIn: WORKFLOW.defaultDateFunction,
			lastActivityDate: null,
			columns: null,
			sort: [{ field: WORKFLOW.orderQueries.fiscalYearEnd, order: "desc" }],

			isViewChanged: false,
			selectedViewId: null,

			clientId: clientId || 0,

			showViews,
			showFilters
		};

		await this.update(newState);

		await this.fetchEngagements({});
	};

	getInitialFlowStates = async () => {
		let { defaultViewSelected } = this.state;
		let flowStates = [];

		let isDefaultViewSelected = defaultViewSelected;

		// If a default view is not selected then send an empty list
		if (!isDefaultViewSelected) {
			return flowStates;
		}

		// If the default view is selected and it is a static view, then apply all the active flow states for all flows
		let isDefaultViewStatic = EngagementService.isDefaultViewStatic({ view: defaultViewSelected.code });

		if (isDefaultViewStatic) {
			// TODO - Should be all valid flow states for all flows
			flowStates = [];

			return flowStates;
		}

		// If a regular default view is selected then just select all flows states for that specific flow
		flowStates = await EngagementService.flattenFlowStates({ flowCode: defaultViewSelected.code });

		return flowStates;
	};

	onSearch = async event => {
		await this.update({
			search: event.target.value
		});
		this.fetchEngagementsDebounce({});
	};

	onLoadMore = async () => {
		let { limit } = this.state;
		await this.update({
			limit: limit + 50
		});

		await this.fetchEngagements({ showLoading: false });

		if (this.state.isAllEngagementsExpanded === false) {
			this.onForceCollapseAll();
		}
	};

	updateEngagement = async ({ engagementId, flowStateId, assignedUsers, notes }) => {
		let { loading } = this.state;

		if (loading) {
			alert({ type: "info", text: "Updating..." });
			return;
		}

		let updateData = { engagementId };

		if (flowStateId) {
			updateData.flowStateId = flowStateId;
		}

		if (assignedUsers) {
			updateData.assignedUsers = assignedUsers;
		}

		if (notes) {
			updateData.notes = notes;
		}

		let { engagement } = await EngagementService.updateEngagement({ request: updateData });

		updateData.flowState = engagement.flow_state;
		updateData.engagement_users = engagement.engagement_users;

		if (!engagement) {
			alert({ type: "error", text: "An error occurred trying to update engagement." });
		} else {
			alert({ type: "success", text: "Updated Successfully!" });
		}

		this.rerenderEngagement({ engagementId, updateData });
	};

	rerenderEngagement = async ({ engagementId, updateData }) => {
		let { notes, flowStateId, flowState, engagement_users } = updateData;
		let { engagements, refresh } = this.state;

		let copy = engagements.slice();

		copy.forEach(engagement => {
			if (engagement.id === engagementId) {
				if (flowStateId) {
					engagement.flow_state_id = flowStateId;
				}

				if (flowState) {
					engagement._flowStateColor = flowState.color;
					engagement.flow_state = flowState;
				}

				if (notes) {
					engagement.external_notes = notes;
				}

				if (engagement_users) {
					engagement.engagement_users = engagement_users;
				}
			}
		});

		await this.update({
			engagements: copy,
			refresh: !refresh
		});
	};

	countEngagements = async () => {
		let {
			clientId,
			search,
			limit,
			offset,
			sort,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			selectedFlowStates,
			shouldShowInactive,
			lastActivityDate
		} = this.state;

		let { count } = await EngagementService.fetchEngagements({
			clientId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			selectedFlowStates,
			shouldShowInactive,
			countOnly: true,
			lastActivityDate
		});

		this.update({
			totalEngagementCount: count
		});
	};

	fetchEngagements = async ({ showLoading = true }) => {
		let {
			clientId,
			search,
			limit,
			offset,
			sort,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			selectedFlowStates,
			shouldShowInactive,
			lastActivityDate
		} = this.state;

		await this.update({
			loading: true && showLoading
		});

		let engagements = await EngagementService.fetchEngagements({
			clientId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			selectedFlowStates,
			shouldShowInactive,
			lastActivityDate
		});

		if (!engagements) {
			await this.update({
				loading: false && showLoading
			});

			return;
		}

		await this.update({
			loading: false && showLoading,
			loadedAll: engagements.length < limit,
			engagements
		});

		await this.countEngagements({ shouldShowInactive });
	};

	sortBy = async ({ field }) => {
		let { sort } = this.state;

		let previousSort = sort;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			sort.push({ field, order: "desc" });
		} else {
			let sortField = sort[index];

			sort.splice(index, 1);
			if (sortField.order === "desc") {
				sortField.order = "asc";

				sort.push(sortField);
			}
		}

		let isViewChanged = false;

		if (JSON.stringify(previousSort) === JSON.stringify(sort)) {
			isViewChanged = true;
		}

		await this.update({
			sort,
			isViewChanged
		});

		await this.fetchEngagements({ showLoading: false });
	};

	renderSortIcon = ({ field }) => {
		let { sort } = this.state;

		let index = -1;
		for (let i = 0; i < sort.length; i++) {
			if (sort[i].field === field) {
				index = i;
				break;
			}
		}

		if (index === -1) {
			return null;
		}

		return sort[index].order === "desc" ? (
			<div className="engs__sort-icon">
				<Icon.ChevronUp size={10} />
				{index + 1}
			</div>
		) : (
			<div className="engs__sort-icon">
				<Icon.ChevronDown size={10} />
				{index + 1}
			</div>
		);
	};

	openEngagementModal = () => {
		this.update({ showEngagementModal: true });
	};

	onEngagementModalClose = () => {
		this.update({ showEngagementModal: false });
	};

	onCreate = async engagementId => {
		await this.update({
			loading: true
		});

		if (engagementId) {
			await this.update({
				showEngagementModal: false,
				showAutoGenerateModal: true,
				newEngagementId: engagementId
			});
		}

		await this.fetchEngagements({});

		await this.update({
			loading: false
		});
	};

	onEnter = (e, engagementId) => {
		if (e.keyCode === 13) {
			this.onSaveEngNotes(engagementId);
		} else if (e.keyCode === 27) {
			this.update({ showNotesEditor: false, engNotes: "" });
		}
	};

	async onSaveEngNotes(engagementId) {
		let { engNotes } = this.state;

		await this.update({
			showNotesEditor: false
		});

		await this.updateEngagement({ engagementId, notes: engNotes });
	}

	onExport = async () => {
		let {
			clientId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			search,
			limit,
			offset,
			sort,
			selectedFlowStates,
			lastActivityDate
		} = this.state;

		let toastId = alert("Export current data ...", {
			timeout: 0
		});

		await EngagementService.exportEngagements({
			clientId,
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			limit,
			offset,
			search,
			sort,
			selectedFlowStates,
			lastActivityDate
		});

		alert({ type: "dismiss", id: toastId });
		alert({ type: "success", text: "Export Complete.", timeout: 3000 });
	};

	onEngagementFilterModalClose = async () => {
		await this.update({
			showFilterModal: false
		});
	};

	onShowFilterModal = async () => {
		await this.update({
			showFilterModal: true
		});
	};

	onFilterChange = async ({ filters, columns }) => {
		let { assignedUsers, assignedClients, assignedClientGroups, assignedTeams, yearEnd, dueDate, dateIn, selectedFlowStates, lastActivityDate } = filters;

		await this.update({
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			columns,
			isViewChanged: true,
			selectedFlowStates,
			lastActivityDate
		});

		await this.fetchEngagements({ showLoading: true });
	};

	onDefaultViewSelected = async ({ view, showInactive }) => {
		await this.update({
			shouldShowInactive: showInactive,
			defaultViewSelected: view
		});

		await this.resetComponent();
	};

	onViewSelected = async ({ view, showInactive }) => {
		let { filters, sort, columns } = JSON.parse(view.schema);

		let { assignedUsers, assignedClients, assignedClientGroups, assignedTeams, yearEnd, dueDate, dateIn, selectedFlowStates } = filters;

		if (!assignedTeams) {
			assignedTeams = [];
		}

		if (!assignedClients) {
			assignedClients = [];
		}

		if (!assignedClientGroups) {
			assignedClientGroups = [];
		}

		if (!yearEnd) {
			yearEnd = WORKFLOW.defaultDateFunction;
		}

		if (!dueDate) {
			dueDate = WORKFLOW.defaultDateFunction;
		}

		if (!dateIn) {
			dateIn = WORKFLOW.defaultDateFunction;
		}
		await this.update({
			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			sort,
			columns,
			selectedFlowStates,
			isViewChanged: false,
			shouldShowInactive: showInactive
		});

		await this.fetchEngagements({});
	};

	shouldDisplayClient() {
		let { columns } = this.state;

		if (_.isEmpty(columns)) {
			return false;
		}

		return true;
	}

	viewHasColumn(columnId) {
		let { columns } = this.state;

		if (_.isEmpty(columns)) {
			return true;
		}

		return columns[columnId];
	}

	safelyDeriveFlowCode = async ({ engagement }) => {
		let flowId = engagement.flow_id;

		try {
			return engagement.flow.code;
		} catch (error) {
			console.log(`Flow Code does not exist on engagement ${engagement.id} - ${error} - fetching manually from backend...`);
			let flow = await EngagementService.fetchFlow({ flowId });
			return flow.code;
		}
	};

	onFlowStateChange = async ({ targetElement, engagement }) => {
		let flowCode = await this.safelyDeriveFlowCode({ engagement });

		FloatingContainerService.openFloatingContainer({
			name: "statusSelector",
			positionBeside: targetElement,

			// Callback fired right before showing the floating component, helpful for add more customization to the behaviours
			beforeShow: component => {
				component.setSelectCallback(item => {
					this.updateEngagement({ engagementId: engagement.id, flowStateId: item.id });
				});

				component.setFlowCode(flowCode);
			}
		});
	};

	onTaskSelect = async ({ task }) => {
		await this.update({
			selectedTaskId: task.id,
			showTaskModal: true
		});
	};

	rerenderTask = async ({ task }) => {
		let { engagements } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			for (let t of newEngagement.tasks) {
				if (t.id === task.id) {
					t.name = task.name;
					t.status = task.status;
				}
			}
		}

		await this.update({
			engagements: newEngagements
		});
	};

	onTaskModalClose = async ({ task }) => {
		if (task) {
			await this.rerenderTask({ task });
		}

		await this.update({
			selectedTaskId: 0,
			showTaskModal: false
		});
	};

	onRecordClick = ({ engagement }) => {
		this.props.history.push(`/engagements/${engagement.id}`);
	};

	onNewTabClick = ({ engagement }) => {
		const route = `/engagements/${engagement.id}`;
		const newTab = window.open(route, "_blank");
		newTab.focus();
	};

	onToggleExpandRecord = async ({ event, engagement }) => {
		event.stopPropagation();

		let { engagements } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			if (newEngagement.id === engagement.id) {
				newEngagement.collapsed = !newEngagement.collapsed;
			}
		}

		await this.update({
			engagements: newEngagements
		});
	};

	onToggleExpandAll = async () => {
		let { engagements, isAllEngagementsExpanded } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			newEngagement.collapsed = isAllEngagementsExpanded;
		}

		await this.update({
			engagements: newEngagements,
			isAllEngagementsExpanded: !isAllEngagementsExpanded
		});
	};

	onForceCollapseAll = async () => {
		let { engagements } = this.state;

		let newEngagements = engagements.slice();

		for (let newEngagement of newEngagements) {
			newEngagement.collapsed = true;
		}

		await this.update({
			engagements: newEngagements,
			isAllEngagementsExpanded: false
		});
	};

	onTaskStatusUpdate = async ({ event, task }) => {
		// event.stopPropagation();

		let updatedTask = null;

		try {
			let newStatus = null;

			if (task.status !== STATUSES.done.id) {
				newStatus = STATUSES.done.id;
			} else {
				newStatus = STATUSES.in_progress.id;
			}

			updatedTask = await TaskService.update({ taskId: task.id, status: newStatus });

			alert({ type: "success", text: "Updated Successfully!" });
		} catch (error) {
			console.log(error);
			alert({ type: "error", text: "Updated error ..." });
		}

		await this.rerenderTask({ task: updatedTask });
	};

	onUserBubblesClicked = ({ targetElement, engagement }) => {
		let assignedUsersFromState = engagement.engagement_users.map(eu => {
			return {
				user_id: eu.user_id,
				role_id: eu.role_id,

				userName: `${eu.User.first_name} ${eu.User.last_name}`,
				roleName: `${eu.Role.name}`
			};
		});

		FloatingContainerService.openFloatingContainer({
			name: "userAssigner",
			positionBeside: targetElement,

			beforeShow: component => {
				component.setEngagementName(engagement.name);
				component.setAssignedUsers(assignedUsersFromState);

				component.setAssignedUsersChangedCallback(assignedUsers => {
					this.updateEngagement({ engagementId: engagement.id, assignedUsers });
				});
			}
		});
	};

	onNotesClicked = ({ targetElement, engagement }) => {
		FloatingContainerService.openFloatingContainer({
			name: "notes",
			positionBeside: targetElement,

			beforeShow: component => {
				component.setEngagementName(engagement.name);
				component.setEngagementId(engagement.id);
				component.setClientId(engagement.client_id);

				component.setOnChangeCallback(notes => {
					this.rerenderEngagement({
						engagementId: engagement.id,
						updateData: { notes }
					});
				});
			}
		});
	};

	renderTaskProgress = ({ engagement }) => {
		let total = engagement.tasks.length;

		if (total === 0) {
			return <div className="t__row__item"></div>;
		}

		let completed = engagement.tasks.filter(task => {
			return task.status === "done";
		}).length;

		let percentage = (completed / total) * 100;

		return (
			<div className="task-progress">
				<div className="task-progress__ratio">
					{completed} / {total}
				</div>
				<div className="task-progress__bar">
					<div className="task-progress__bar__completed" style={{ width: `${percentage}%` }}></div>
				</div>
			</div>
		);
	};

	renderEngagementCardTasks({ tasks }) {
		let hasAnyTasks = !tasks || tasks.length === 0;

		return (
			<div className="engagement-card__tasks">
				{hasAnyTasks && <div className="engagement-card__tasks__no-tasks">No Tasks</div>}

				{tasks.map(task => {
					return (
						<div className="engagement-card__tasks__item" key={task.id}>
							<div>{task.name}</div>
							<Checkbox
								name="selectAll"
								checked={task.status === STATUSES.done.id}
								onChange={({ event }) => this.onTaskStatusUpdate({ event, task })}
								isSmall
							/>
						</div>
					);
				})}
			</div>
		);
	}

	EngagementCard = props => {
		let { id, engagement } = props;

		let { Client: client, Team: team, flow_state: flowState, flow, name, tasks, external_notes: notes } = engagement;

		let clientName = client && client.name ? client.name : "";
		let teamName = team && team.name ? team.name : "";

		let title = `Client: ${clientName}`;

		if (teamName) {
			title += ` - Team: ${teamName}`;
		}

		let statusElementRef = null;
		let notesElementRef = null;

		return (
			<div key={id} className="engagement-card" onClick={() => {}}>
				{client && <div className="engagement-card__label">{title}</div>}
				<div className="engagement-card__divider" />
				<div className="engagement-card__title" onClick={() => this.onRecordClick({ engagement })}>
					{name}
				</div>
				<div className="engagement-card__divider" />
				<div
					className="engagement-card__eng-flow-state"
					style={{ backgroundColor: flowState.color }}
					ref={ref => (statusElementRef = ref)}
					onClick={() => this.onFlowStateChange({ engagement, targetElement: statusElementRef })}
				>
					{flowState.name}
				</div>
				{this.renderEngagementCardTasks({ tasks })}
				{notes.length > 0 && (
					<div
						className="engagement-card__notes"
						ref={ref => (notesElementRef = ref)}
						onClick={() => this.onNotesClicked({ engagement, targetElement: notesElementRef })}
					>
						{notes.length} engagement notes
					</div>
				)}
				<div className="engagement-card__dates">
					<div className="engagement-card__dates__item">Year End - {moment(engagement.fiscal_year_end).format("YYYY-MM-DD")}</div>
				</div>
				<div className="engagement-card__dates">
					<div className="engagement-card__dates__item">Date In - {moment(engagement.date_in).format("YYYY-MM-DD")}</div>
					<div className="engagement-card__dates__item">Due Date - {moment(engagement.due_date).format("YYYY-MM-DD")}</div>
				</div>
			</div>
		);
	};

	buildDeadlineKanbanStyle = () => {
		let { engagements } = this.state;

		let lanes = Object.values(DEADLINES).map(deadline => {
			return {
				id: deadline.id,
				title: deadline.name,
				range: deadline.range,
				cards: [],
				style: {
					backgroundColor: deadline.color
				},
				cardStyle: {
					borderLeft: `4px solid ${deadline.color}`
				}
			};
		});

		// Object keeps track if a task has been categorized already
		let categorizedEngagements = {};

		for (let engagement of engagements) {
			for (let lane of lanes) {
				// Parse deadline from the engagement payload
				let deadline = moment(engagement.due_date);

				// Range is stored within the lange object
				let { start, end } = lane.range;

				// Confirm if the task is within the deadline range
				let isWithinDeadlineRange = deadline.isBetween(start, end, "hour", "[]");

				// Confirm that the engagement is within the range set for this particular lane and that we have not already categorized it before
				if (isWithinDeadlineRange && !categorizedEngagements[engagement.id]) {
					// Cache a true value to indicate we have categorized this particular task
					categorizedEngagements[engagement.id] = true;

					// Push the task on top the react-trello payload
					lane.cards.push({
						id: engagement.id,
						engagement
					});
				}
			}
		}

		const data = {
			lanes
		};

		return data;
	};

	renderKanban() {
		let data = {};

		data = this.buildDeadlineKanbanStyle();

		return (
			<Board
				className="kb-container"
				style={{ backgroundColor: "#eaedf3", overflowX: "scroll" }}
				data={data}
				components={{ Card: this.EngagementCard }}
				cardDraggable={false}
			/>
		);
	}

	onPageViewSelect = async pageView => {
		await this.update({ pageView: pageView.id });
	};

	getFilterSchema = () => {
		let { assignedUsers, assignedClients, assignedClientGroups, assignedTeams, yearEnd, dueDate, dateIn, sort, columns, selectedFlowStates } = this.state;

		let schema = {
			filters: {
				selectedFlowStates,
				assignedUsers,
				assignedClients,
				assignedClientGroups,
				assignedTeams,
				yearEnd,
				dueDate,
				dateIn
			},
			columns,
			sort
		};

		return schema;
	};

	renderTaxEstimate({ taxEstimate }) {
		if (!taxEstimate) {
			return "No";
		}
		return "Yes";
	}

	renderList = () => {
		let { engagements, showNotesEditor, engNotes, loadedAll } = this.state;
		let { renderTaxEstimate } = this;
		return (
			<ScrollableContainer>
				<div className={clsx("t", "ft_t_e")}>
					<div className={"t__header"}>
						{this.viewHasColumn(WORKFLOW.columns.name.id) && (
							<div
								className={clsx("t__header__item", "engs__header__item--name")}
								onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.name })}
							>
								Name {this.renderSortIcon({ field: WORKFLOW.orderQueries.name })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.client.id) && this.shouldDisplayClient() && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.clientName })}>
								Client {this.renderSortIcon({ field: WORKFLOW.orderQueries.clientName })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.type.id) && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.type })}>
								Type {this.renderSortIcon({ field: WORKFLOW.orderQueries.type })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.team.id) && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.team })}>
								Team {this.renderSortIcon({ field: WORKFLOW.orderQueries.team })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.users.id) && <div className="t__header__item">Users</div>}

						{this.viewHasColumn(WORKFLOW.columns.status.id) && (
							<div
								className={clsx("t__header__item", "engs__header__item--status")}
								onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.status })}
							>
								Status {this.renderSortIcon({ field: WORKFLOW.orderQueries.status })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.lastNote.id) && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.notes })}>
								Last Note {this.renderSortIcon({ field: WORKFLOW.orderQueries.notes })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.taskProgress.id) && (
							<div className={clsx("t__header__item", "engs__header__item--task-prog")}>Task Progress</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.dateIn.id) && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.dateIn })}>
								Date In {this.renderSortIcon({ field: WORKFLOW.orderQueries.dateIn })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.dueDate.id) && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.dueDate })}>
								Due Date {this.renderSortIcon({ field: WORKFLOW.orderQueries.dueDate })}
							</div>
						)}

						{this.viewHasColumn(WORKFLOW.columns.fiscalYearEnd.id) && (
							<div className="t__header__item" onClick={() => this.sortBy({ field: WORKFLOW.orderQueries.fiscalYearEnd })}>
								Fiscal Year End {this.renderSortIcon({ field: WORKFLOW.orderQueries.fiscalYearEnd })}
							</div>
						)}
						{this.viewHasColumn(WORKFLOW.columns.taxEstimate.id) && <div className="t__header__item">Tax Estimate</div>}
					</div>

					{engagements.map((eng, index) => {
						let flowStates = eng.flow.flow_states;

						// TODO: Should be done on the backend
						flowStates = flowStates.sort((fs1, fs2) => {
							if (fs1.order < fs2.order) {
								return -1;
							}

							if (fs1.order > fs2.order) {
								return 1;
							}

							return 0;
						});

						for (let fs of flowStates) {
							fs.key = fs.id;
							fs.value = fs.name;
						}

						let hasAnyNotes = eng.external_notes.length > 0;
						let lastNote = eng.external_notes[eng.external_notes.length - 1];

						let note = hasAnyNotes ? lastNote.content : "";

						// XXX - Super gross
						if (!eng._flowStateColor) {
							eng._flowStateColor = eng.flow_state ? eng.flow_state.color : "white";
						}

						if (typeof eng.collapsed === "undefined") {
							eng.collapsed = eng.tasks.length === 0;
						}

						const showTasks = !eng.collapsed;
						let statusColumnRef = null;
						let userBubblesColumnRef = null;
						let notesColumnRef = null;

						let alreadyRenderedDivider = false;

						return (
							<React.Fragment key={`${index}-${eng.id}`}>
								<div className={clsx("t__row", "ft_r_e")} data-engid={eng.id} style={{ backgroundColor: eng._flowStateColor }}>
									{this.viewHasColumn(WORKFLOW.columns.name.id) && (
										<div
											className={clsx("t__row__item", "engs__row__item--name")}
											onClick={() => {
												this.onRecordClick({ engagement: eng });
											}}
										>
											<div
												className="engs__row__item__name-chevron"
												onClick={event => this.onToggleExpandRecord({ event, engagement: eng })}
											>
												{eng.collapsed ? (
													<Icon.ChevronRight size={12} color="var(--primary-main)" className="ft-c-e" />
												) : (
													<Icon.ChevronDown size={12} color="var(--primary-main)" className="ft-e-e" />
												)}
											</div>

											<div className="engs__row__item__name-value">{eng.name}</div>

											<IconExternalLink
												className="engs__row__item__external-link-icon"
												size={14}
												onClick={() => this.onNewTabClick({ engagement: eng })}
											/>
										</div>
									)}
									{this.viewHasColumn(WORKFLOW.columns.client.id) && this.shouldDisplayClient() && (
										<div className="t__row__item">{eng.Client.name}</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.type.id) && <div className="t__row__item ">{eng.flow.name}</div>}

									{this.viewHasColumn(WORKFLOW.columns.team.id) && (
										<div className="t__row__item">{eng.Team ? eng.Team.name : "Unassigned"}</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.users.id) && (
										<div
											ref={ref => (userBubblesColumnRef = ref)}
											id="user-column"
											className="t__row__item"
											onClick={() => this.onUserBubblesClicked({ targetElement: userBubblesColumnRef, engagement: eng })}
										>
											<UserBubbles engagementUsers={eng.engagement_users} />
										</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.status.id) && (
										<div
											ref={ref => (statusColumnRef = ref)}
											className={clsx("t__row__item", "engs__row__item--status")}
											onClick={() => this.onFlowStateChange({ engagement: eng, targetElement: statusColumnRef })}
										>
											{eng.flow_state.name}
										</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.lastNote.id) && showNotesEditor !== eng.id && (
										<div
											ref={ref => (notesColumnRef = ref)}
											className={clsx("t__row__item", "ft-and-e")}
											onClick={() => this.onNotesClicked({ engagement: eng, targetElement: notesColumnRef })}
										>
											{hasAnyNotes && <>{note}&nbsp;</>}
											{!hasAnyNotes && <Button className="engs__row__item__note-btn" text="Add a note" view="secondary" />}
										</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.lastNote.id) && showNotesEditor === eng.id && (
										<div className="t__row__item">
											<input
												ref={ref => (this.notesEditor = ref)}
												placeholder="Enter some notes ..."
												value={engNotes}
												onKeyDown={event => this.onEnter(event, eng.id)}
												onChange={event => this.setState({ engNotes: event.target.value })}
											/>
											{/* TODO: looks like we are not using this */}
											{/* <div className="workflow-notes-editor__button" onClick={() => this.onSaveEngNotes(eng.id)}>
									<Icon.Check size={14} />
								</div>
								<div
									className="workflow-notes-editor__button"
									onClick={() => this.setState({ showNotesEditor: false, engNotes: "" })}
								>
									<Icon.X size={14} />
								</div> */}
										</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.taskProgress.id) && (
										<div className={clsx("t__row__item", "engs__row__item--task-prog")}>{this.renderTaskProgress({ engagement: eng })}</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.dateIn.id) && (
										<div className="t__row__item">{moment(eng.date_in).format("YYYY-MM-DD")}</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.dueDate.id) && (
										<div className="t__row__item">{moment(eng.due_date).format("YYYY-MM-DD")}</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.fiscalYearEnd.id) && (
										<div className="t__row__item">{moment(eng.fiscal_year_end).format("YYYY-MM-DD")}</div>
									)}

									{this.viewHasColumn(WORKFLOW.columns.taxEstimate.id) && (
										<div className="t__row__item">{renderTaxEstimate({ taxEstimate: eng.tax_estimate })}</div>
									)}
								</div>

								{/* ENGAGEMENT TASKS */}
								{showTasks &&
									eng.tasks.map((task, index) => {
										let isLastTask = index === eng.tasks.length - 1;
										alreadyRenderedDivider = isLastTask;
										return (
											<React.Fragment key={index}>
												<div
													className={clsx("t__row", "engs__task-row", "ft-trow-e")}
													data-parent-engid={eng.id}
													data-taskid={task.id}
													style={{ backgroundColor: STATUSES[task.status].color }}
												>
													<div className={"t__row__item"} onClick={() => this.onTaskSelect({ task })}>
														<Icon.CornerDownRight size={12} color="var(--primary-main)" />

														<Checkbox
															containerClass="engs__row___item__checkbox"
															className="ft-tr-cb-e"
															name="selectAll"
															checked={task.status === STATUSES.done.id}
															onChange={({ event }) => this.onTaskStatusUpdate({ event, task })}
															isSmall
														/>
														<div className="t__row__item">{task.name}</div>
													</div>
												</div>{" "}
												{isLastTask && <hr className="t__row__divider" />}
											</React.Fragment>
										);
									})}

								{!alreadyRenderedDivider && <hr className="t__row__divider" />}
							</React.Fragment>
						);
					})}
					{!loadedAll && <Button view="secondary" text="Load More" onClick={this.onLoadMore} />}
				</div>
			</ScrollableContainer>
		);
	};

	render() {
		let {
			selectedFlowStates,
			showFilterModal,

			loading,
			search,

			engagements,
			showAutoGenerateModal,
			newEngagementId,
			clientId,

			assignedUsers,
			assignedClients,
			assignedClientGroups,
			assignedTeams,
			yearEnd,
			dueDate,
			dateIn,
			columns,

			showTaskModal,
			selectedTaskId,

			kanbanStyle,
			pageView,
			showFilters,
			showEngagementModal,

			isViewChanged,

			totalEngagementCount,

			showViews,
			lastActivityDate
		} = this.state;
		let { title } = this.props;

		if (!title) {
			title = "Engagements";
		}

		return (
			<div className="engs">
				<PageHeading section="Workflow" title={title} />

				<div className="engs__header">
					{totalEngagementCount > 0 && <div className="engs__header__total">Total Engagements: {totalEngagementCount}</div>}

					<div className="engs__header__actions">
						<Tabs onSelect={this.onPageViewSelect} selected={pageView}>
							<Tab id={"list"} icon={<ListChecks size={18} strokeWidth={1.5} />} />
							<Tab id={"kanban"} icon={<Trello size={18} strokeWidth={1.5} />} />
						</Tabs>

						<Button view="secondary" className={"ft-cb-e"} onClick={this.onToggleExpandAll} icon={<CopyMinus size={16} />} />

						{showFilters && <Button view="secondary" onClick={this.onShowFilterModal} icon={<Icon.Sliders size={16} />} className={"ft-ef-e"} />}

						<Button view="secondary" onClick={this.openEngagementModal} icon={<Icon.Plus size={16} />} className={"ft-ae-e"} />

						<Button view="secondary" onClick={this.onExport} icon={<Icon.DownloadCloud size={16} />} className={"ft-de-e"} />

						<SearchInput onChange={this.onSearch} value={search} />
					</div>
				</div>

				<div className="test-container">
					{showViews && (
						<ViewList
							type={"engagement"}
							isViewChanged={isViewChanged}
							getSchema={this.getFilterSchema}
							onViewSelected={this.onViewSelected}
							onDefaultViewSelected={this.onDefaultViewSelected}
						/>
					)}

					{loading && (
						<div className="container-loader">
							<Loader />
						</div>
					)}

					{engagements.length === 0 && !loading && (
						<div className="container-empty">
							<div>
								<Icon.Frown size={64} />
							</div>
							<div>... no engagements ...</div>
						</div>
					)}

					{engagements.length > 0 && !loading && (
						<>
							{pageView == "kanban" && this.renderKanban()}
							{pageView == "list" && this.renderList()}
						</>
					)}
				</div>

				<EngagementModal
					show={showEngagementModal}
					clientId={clientId}
					onCreate={this.onCreate}
					onClose={this.onEngagementModalClose}
					{...this.props}
				/>
				<AutoGenerateModal show={showAutoGenerateModal} engagementId={newEngagementId} {...this.props} />
				<EngagementFilterModal
					show={showFilterModal}
					onClose={this.onEngagementFilterModalClose}
					onFilterChange={this.onFilterChange}
					filters={{
						assignedUsers,
						assignedClients,
						assignedClientGroups,
						assignedTeams,
						yearEnd,
						dueDate,
						dateIn,
						columns,
						selectedFlowStates,
						lastActivityDate
					}}
				/>

				<TaskModal taskId={selectedTaskId} show={showTaskModal} onClose={this.onTaskModalClose} {...this.props} />
			</div>
		);
	}
}

export default Engagements;
