// External Libraries
import React from "react";
import * as Icon from "react-feather";
import _ from "lodash";
import { motion } from "framer-motion";
import clsx from "clsx";
import { Tooltip } from "react-tippy";

// Services
import UserService from "../../../services/UserService";
import EngagementService from "../../../services/EngagementService";
import UtilityService from "../../../services/UtilityService";

// Common Components
import ViewModal from "../ViewModal/ViewModal";
import WComponent from "../../common/WComponent";
import Checkbox from "../../common/checkbox/Checkbox";
import Button from "../../common/button/Button";

import { DEFAULT_VIEWS } from "../../../constants/Views";

import "./view-list.css";
import "react-tippy/dist/tippy.css";

const { alert } = UtilityService;

const variants = {
    visible: {
        width: 200,
        padding: 15,
        opacity: 1,
        transition: {
            duration: 0.2,
        },
    },
    hidden: {
        width: 0,
        padding: 0,
        opacity: 0,
        transition: {
            duration: 0.1,
        },
    },
};

const types = {
    ENGAGEMENT: "engagement",
    CLIENT: "client",
    TASK: "task",
};

const ALL_DEFAULT_VIEW = DEFAULT_VIEWS[0];

class ViewList extends WComponent {
    constructor(props) {
        super(props);

        this.state = {
            views: [],

            isViewChanged: false,

            savingView: false,
            overwritingView: false,

            showViews: true,
            minimizeViews: false,

            showViewModal: false,
            selectedViewId: null,
            viewObject: null,

            defaultViews: [...DEFAULT_VIEWS],
            currentDefaultViewSelected: null,
            showInactive: false,
        };
    }

    componentDidMount = () => {
        this.resetComponent();
    };

    componentDidUpdate = async (prevProps) => {
        let viewChanged = prevProps.isViewChanged !== this.props.isViewChanged;

        if (viewChanged) {
            this.resetComponent();
        }
    };

    isEngagementViewType = () => {
        let { type } = this.props;
        return type && type.toLowerCase() === types.ENGAGEMENT;
    };

    resetComponent = async () => {
        let { selectedViewId, isViewChanged: isViewChangedFromState, currentDefaultViewSelected } = this.state;
        let { isViewChanged: isViewChangedFromProp, viewType } = this.props;

        let hasViewChanged = isViewChangedFromState || isViewChangedFromProp;

        await this.update({
            selectedViewId,
            viewType,
            isViewChanged: hasViewChanged,
            defaultViews: [],
            currentDefaultViewSelected,
            showInactive: false,
        });

        // If no custom or default view is selected, then select the ALL_DEFAULT_VIEW
        if (!selectedViewId && !currentDefaultViewSelected) {
            this.onDefaultViewSelected(ALL_DEFAULT_VIEW);
        }

        await Promise.all([this.fetchDefaultViews(), this.fetchCustomViews()]);
    };

    fetchCustomViews = async () => {
        let { type } = this.props;

        // fetch custom views
        let views = await EngagementService.fetchViews({ type, layout: "custom" });
        this.update({ views });
    };

    onCreateNewView = async () => {
        this.update({
            selectedViewId: null,

            showViewModal: true,
            isViewChanged: false,
        });
    };

    onViewModalClose = async () => {
        this.update({
            showViewModal: false,
        });
    };

    onViewDelete = async ({ viewId, status }) => {
        await this.update({
            savingView: true,
        });

        let view = await EngagementService.updateView({ viewId, status });

        await this.update({
            showViewModal: false,
            isViewChanged: false,
            savingView: false,
        });

        if (!view) {
            alert({ type: "success", text: "An error occurred trying to delete this view." });
        } else {
            alert({ type: "success", text: "View Deleted Successfully!" });
        }

        await this.fetchCustomViews();
    };

    // Function to ensure the current user is included in the list of user IDs
    addUserIfNotExist = (assignedUsersForView) => {
        // Extracting the list of user IDs from assigned users
        let userIds = assignedUsersForView.map((user) => user.user_id);

        // Get the user ID of the requester
        let { userId } = UserService.getUserData();

        // Check if the requester user ID exists in the list
        let exists = userIds.includes(userId);

        // If the user ID doesn't exist, add it to the list
        if (!exists) {
            userIds.push(userId);
        }

        return userIds;
    };

    onViewSave = async ({ viewId, name, isShared, assignedUsers: assignedUsersForView }) => {
        let { savingView, overwritingView } = this.state;
        let { type } = this.props;

        let isNewView = viewId === null;

        if (savingView || overwritingView) {
            return;
        }

        await this.update({
            savingView: true,
        });

        let schema = this.props.getSchema();
        schema = JSON.stringify(schema, null, 4);

        let userIds = this.addUserIfNotExist(assignedUsersForView);

        let view = null;

        if (isNewView) {
            view = await EngagementService.createView({ name, schema, userIds, isShared, type });
        } else {
            view = await EngagementService.updateView({ viewId, name, schema, userIds, isShared, type });
        }

        await this.update({
            showViewModal: false,
            isViewChanged: false,
            savingView: false,
        });

        if (!view) {
            alert({ type: "success", text: "An error occurred trying to create a view." });
        } else {
            alert({ type: "success", text: "View Created Successfully!" });
        }

        await this.fetchCustomViews();
    };

    onDefaultViewSelected = async (view) => {
        let { showInactive } = this.state;

        await this.update({
            selectedViewId: null,
        });

        if (this.props.onDefaultViewSelected) {
            this.props.onDefaultViewSelected({ view, showInactive });
        }

        this.update({
            currentDefaultViewSelected: view,
            isViewChanged: false,

            viewObject: view,
        });
    };

    toggleShowInactive = async (event) => {
        await this.update({
            showInactive: event.target.checked,
        });

        let { currentDefaultViewSelected, viewObject } = this.state;

        if (!viewObject) {
            return;
        }

        // Is a default view that is currently selected
        if (currentDefaultViewSelected) {
            this.onDefaultViewSelected(viewObject);
            return;
        }

        this.onViewSelected({ view: viewObject });
    };

    onViewEditSelected = async ({ event, view }) => {
        event.stopPropagation();
        event.preventDefault();

        await this.update({
            selectedViewId: view.id,
            showViewModal: true,
        });
    };

    onViewSelected = async ({ event, view }) => {
        let { showInactive } = this.state;

        await this.update({
            selectedViewId: view.id,
            isViewChanged: false,
            currentDefaultViewSelected: null,

            viewObject: view,
        });

        this.props.onViewSelected({ view, showInactive });
    };

    onOverwriteCurrentView = async () => {
        let { selectedViewId, savingView, overwritingView } = this.state;
        let { type } = this.props;

        if (savingView || overwritingView) {
            return;
        }

        this.update({
            overwritingView: true,
        });

        // Retrieve the view "schema" from the parent
        // Example payload:
        // {
        // 	filters: { flowId, flowStateId, ... },
        // 	sort: { ... }
        // 	columns: { ... }
        // }
        let schema = this.props.getSchema();

        schema = JSON.stringify(schema, null, 4);

        let updatedView = await EngagementService.updateView({ viewId: selectedViewId, schema, type });

        if (!updatedView) {
            alert({ type: "success", text: "An error occurred trying to overwrite this view." });
        } else {
            alert({ type: "success", text: "View updated successfully!" });
        }

        await this.update({
            isViewChanged: false,
            overwritingView: false,
        });

        await this.fetchCustomViews();
    };

    isDefaultViewSelected = () => {
        return this.state.selectedViewId === null;
    };

    onToggleViews = () => {
        let { minimizeViews } = this.state;
        this.update({
            minimizeViews: !minimizeViews,
        });
    };

    async fetchDefaultViews() {
        const flows = await EngagementService.fetchFlows({});
        const flowsMasked = flows.map((flow) => ({ id: flow.id, code: flow.code, flowName: flow.name }));

        let defaultViews = [...DEFAULT_VIEWS, ...flowsMasked];

        this.update({ defaultViews });
    }

    handleDragStart = (event, draggedView) => {
        event.dataTransfer.setData("view", JSON.stringify(draggedView));
    };

    handleDrop = async ({ event }) => {
        const { views } = this.state;

        // Check if views is a valid array
        if (!Array.isArray(views)) {
            console.error("Views is not an array or is undefined:", views);
            return;
        }

        // Parse the dropped view data
        const droppedViewData = event.dataTransfer.getData("view");
        if (!droppedViewData) {
            console.error("No view data found in event.dataTransfer");
            return;
        }

        let droppedView;
        try {
            droppedView = JSON.parse(droppedViewData);
        } catch (error) {
            console.error("Failed to parse dropped view data:", error);
            return;
        }

        // Get the target ID from the event target
        const targetId = event.target.getAttribute("data-id");
        if (!targetId) {
            console.error("Target ID is null or undefined");
            return;
        }

        let targetIndex = views.findIndex((view) => view.id == targetId);
        let draggedIndex = views.findIndex((view) => view.id == droppedView.id);

        // Validate indices
        if (targetIndex === -1 || draggedIndex === -1) {
            console.error(`Invalid indices: targetIndex=${targetIndex}, draggedIndex=${draggedIndex}`);
            return;
        }

        // Create a copy of the views array
        let updatedViews = [...views];

        // Reorder views
        const [removedView] = updatedViews.splice(draggedIndex, 1);
        updatedViews.splice(targetIndex, 0, removedView);

        // Update state with the new views order
        this.update({ views: updatedViews });

        // Prepare view IDs for backend update
        const viewIds = updatedViews.map((view) => view.id);
        console.log("viewIds", viewIds);

        try {
            // Update view's sort_order via backend service
            await EngagementService.updateViewUser({ viewIds });
            console.log("View order updated successfully");
        } catch (error) {
            console.error("Failed to update view order:", error);
        }
    };

    handleDragOver = (event) => {
        event.preventDefault();
    };

    renderDefaultViews() {
        const { defaultViews, currentDefaultViewSelected, minimizeViews } = this.state;

        return (
            <div>
                {this.renderAdditionalFilters()}
                <div className="view-list__divider"></div>
                <div className="view-list__body__list__title">Default Views</div>
                {defaultViews.map((view) => {
                    const isSelected = currentDefaultViewSelected && currentDefaultViewSelected.id === view.id;

                    return (
                        <div className={clsx("view-list__body__list__item", { "view-list__body__list__item--selected": isSelected })} onClick={() => this.onDefaultViewSelected(view)}>
                            {view.flowName}
                        </div>
                    );
                })}
            </div>
        );
    }

    renderCustomViews() {
        let { views, selectedViewId, minimizeViews } = this.state;

        // Variants for motion animation
        const variants = {
            hidden: { opacity: 0, y: -20 },
            visible: { opacity: 1, y: 0, transition: { duration: 0.3 } },
        };

        return (
            <motion.div
                className=""
                variants={variants}
                initial="hidden"
                animate={minimizeViews ? "hidden" : "visible"}
                onDrop={(event) => this.handleDrop({ event })}
                onDragOver={this.handleDragOver}
            >
                {!this.isEngagementViewType() && (
                    <div className={clsx("view-list__body__list__item", { "view-list__body__list__item--selected": this.isDefaultViewSelected() })} onClick={this.onDefaultViewSelected}>
                        Default
                    </div>
                )}

                <div className="view-list__divider"></div>
                <div className="view-list__body__list__title">Custom Views</div>

                {views.map((view, index) => {
                    const isSelected = selectedViewId === view.id;

                    return (
                        <motion.div
                            className={clsx("view-list__body__list__item", { "view-list__body__list__item--selected": selectedViewId === view.id })}
                            draggable
                            onDragStart={(event) => this.handleDragStart(event, view)}
                            onClick={(event) => this.onViewSelected({ event, view })}
                            key={view.name + "-" + view.id}
                            data-id={view.id}
                            layout
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                        >
                            <Tooltip arrow title={view.name}>
                                <div>{view.name}</div>
                            </Tooltip>
                            <div className="view-list__body__list__item__action" onClick={(event) => this.onViewEditSelected({ event, view })}>
                                <Icon.Edit2 size={16} className="ft-view-edit" />
                            </div>
                        </motion.div>
                    );
                })}
            </motion.div>
        );
    }

    renderViewChangedOptions() {
        let { isViewChanged, savingView, overwritingView } = this.state;

        if (!isViewChanged) {
            return null;
        }

        const viewBtnText = !savingView ? "Create new view" : "Saving ...";
        const overwriteBtnTxt = !overwritingView ? "Overwrite current view" : "Saving ...";

        return (
            <div className="view-list__body__unsaved">
                <div className="view-list__body__unsaved__blurb">
                    <Icon.AlertTriangle className="view-list__body__unsaved__blurb__icon" size={21} /> You have unsaved changes to the filters.
                </div>

                <Button className="view-list__body__unsaved__btn" view="secondary" text={viewBtnText} onClick={this.onCreateNewView} />

                {!this.isDefaultViewSelected() && <Button className="view-list__body__unsaved__btn" view="secondary" text={overwriteBtnTxt} onClick={this.onOverwriteCurrentView} />}
            </div>
        );
    }

    renderAdditionalFilters() {
        let { showInactive } = this.state;
        return <Checkbox containerClass="view-list__additional-filter" name="inactive" title="Show Inactive" checked={showInactive} onChange={this.toggleShowInactive} className="ft-iac-e" />;
    }

    renderViews() {
        let { showViews, minimizeViews } = this.state;

        if (!showViews) {
            return null;
        }

        return (
            <div className="view-list">
                <motion.div className="view-list__body" variants={variants} initial="hidden" animate={minimizeViews ? "hidden" : "visible"}>
                    {!minimizeViews && (
                        <>
                            {this.renderViewChangedOptions()}
                            {this.renderDefaultViews()}
                            {this.renderCustomViews()}
                        </>
                    )}
                </motion.div>
                <div className="view-list__arrow" onClick={this.onToggleViews}>
                    {minimizeViews ? <Icon.List size={18} className="ft-vlc-e" /> : <Icon.ChevronLeft size={18} className="ft-vle-e" />}
                </div>
            </div>
        );
    }

    render() {
        let { showViewModal, selectedViewId } = this.state;

        return (
            <>
                {this.renderViews()}
                <ViewModal viewId={selectedViewId} show={showViewModal} onClose={this.onViewModalClose} onSave={this.onViewSave} onDelete={this.onViewDelete} />
            </>
        );
    }
}

export default ViewList;
